import { useCallback, useContext, useEffect, useState } from "react";
import "./styles.scss";

import NewProfile from "../Cards/NewProfile";
import CardsCatalogDetails from "../Cards/CardsCatalogDetails";
import CardsMediaLibrary from "../Cards/CardsMediaLibrary";
import Button from "../Buttons/Button";
import ButtonIcon from "../Buttons/ButtonIcon";
import { profile } from "console";
import PageTitle from "../../components/PageTitles";

import { ICatalogResponse } from "../../types/catalog";
import { Chat } from "../../types/chatInfo";
import { IToast } from "../../types/toast";

import { useChat } from "../../contexts/openedChatContext";
import { useWebSocket } from "../../contexts/webSocketContext";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";

import CreateChatContact from "../../api/postCreateChatContact";
import postAddToFavorite from "../../api/favorite/postAddToFavorite";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import deleteRemoveFromFavorites from "../../api/favorite/deleteRemoveFromFavorites";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import video from "../../assets/videos/video-profile-test.mp4";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";

const ProducerInteractionPage: React.FC = () => {
  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    params,
  } = useDeskNavigation();
  const { getChats } = useWebSocket();
  const { isChatPageOpen, setIsChatPageOpen } = useContext(
    ToggleChatOpenContext
  );
  const [chats, setChats] = useState<Chat[]>([]);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    isVip: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });

  const { userId } = params;
  const { userInfo } = useUserInfo();
  const { setActiveChatId } = useChat();
  const [photoProducer, setPhotoProducer] = useState(
    producerDetails.profile.photos || []
  );
  const [photoSelect, setPhotoSelect] = useState("");
  const [videoPublic, setVideoPublic] = useState<string | null>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isChatPageOpen) {
      sendMessageClick();
    }
  }, [userId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params]);

  useEffect(() => {
    const producerDetail = async () => {
      if (userInfo.access_token) {
        const response = await getPublicDetails(userInfo.access_token, userId);

        switch (response?.status) {
          case 200:
            setIsProfileFavorite(response.res.isFavorite);
            setProducerDetails(response.res);
            setPhotoProducer(response.res.profile.photos);
            if (response.res.profile.cover) {
              setVideoPublic(response.res.profile.cover?.url);
              setPhotoSelect(response.res.profile.cover?.url);
            } else {
              setPhotoSelect(response.res.profile.photos[0]?.url);
            }
            setCurrentIndex(0);

            break;
          default:
            // setPrincipalScreen("");
            break;
        }
      }
    };
    producerDetail();
  }, [userId]);

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(
        userInfo.access_token,
        producerDetails.userId
      );
      switch (response.status) {
        case 201:
        case 204:
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        producerDetails.userId
      );

      switch (response.status) {
        case 204:
        case null:
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    }
  };

  // Set unlocked chats
  const getListChats = useCallback(async () => {
    getChats({}, (res: Chat[]) => {
      const openedChatsIds: string[] | [] = res?.map(
        (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
      );
      setOpenedChatUserIds(openedChatsIds);
      setChats(res);
    });
  }, [getChats, userId]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  const sendMessageClick = async () => {
    if (userInfo.access_token && userId && userId !== "0") {
      const isOpenedChat = openedChatUserIds?.includes(userId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        userId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };
  // End Set unlocked chats

  const backPageHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("");
    } else {
      setPrincipalScreen("");

      setSecondaryScreen("");
      setTertiaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const sendMessageHandler = () => {
    setIsChatPageOpen(true);
    sendMessageClick();
  };

  /* TODO: Somebody is doing */
  const handlerFeedButtonClick = () => {};

  return (
    <div id="producer-interaction">
      <PageTitle
        onBackClick={backPageHandler}
        hasCloseIcon={windowSize >= 768}
        className="padding-hor-24"
        buttonFeed
        buttonFeedClick={() => setFeedScreen("/feed", { userId })}
      />
      <NewProfile
        age={producerDetails?.profile.age}
        // isVerified
        isVip={producerDetails.isVip}
        idProfile={userId}
        name={producerDetails.displayname}
        principalPhoto={photoSelect}
        profilePhoto={photoProducer[0]?.url}
        // profileDetails={["Brazilian", "5’4” / 1,67m", "56kg / 123lb"]}
        // rating={producerDetails.profile.rating || Math.floor(Math.random() * 6)}
        heartButtonClick={clickLikeHandler}
        sendMessageClick={sendMessageHandler}
        buttonStyle={isProfileFavorite ? "transparentActive" : "transparent"}
        icon={isProfileFavorite ? "icon-heart-solid" : "icon-heart-outline"}
        iconSecondary={"icon-messages"}
        photos={photoProducer}
        changeNext={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex]?.url);
              return 1;
            }
            if (prevIndex === 0 && !photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex + 1]?.url);
              return 1;
            }

            const nextIndex = prevIndex + 1;
            if (nextIndex < photoProducer.length) {
              setPhotoSelect(photoProducer[prevIndex]?.url || "");
              return nextIndex;
            }

            setPhotoSelect(photoProducer[photoProducer.length - 1]?.url || "");
            return prevIndex;
          });
        }}
        changePrevious={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && videoPublic?.endsWith(".mp4")) {
              setPhotoSelect(videoPublic);
              return 0;
            }
            if (prevIndex > 0) {
              const previousIndex = prevIndex - 1;
              setPhotoSelect(photoProducer[previousIndex]?.url || "");
              return previousIndex;
            }
            return prevIndex;
          });
        }}
      />
      {/* <div className="list-items-details margin-hor-24">
        {Object.entries(detailsMock).map(([detail, value], index) => (
          <ListItemSecondary
            key={index}
            showLabel
            labelContent={
              detail === "rosesToConnect"
                ? "Roses to Connect"
                : detail === "rosesToMeet"
                ? "Roses to Meet"
                : detail === "hairColor"
                ? "Hair Color"
                : detail.charAt(0).toUpperCase() + detail.slice(1)
            }
            showValue
            valueContent={value || ""}
            showSeparator={Object.entries(detailsMock).length > index + 1}
          />
        ))}
      </div>

      <div className="photo-album padding-hor-24">
        {cardsLibrary.map((card, index) => (
          <CardsMediaLibrary
            key={index}
            image={card.img}
            altImage={card.alt}
            rosesPrice={card.price}
            unlockClick={() => {}}
            isBlurred={parseFloat(card.price) > 0}
          />
        ))}
      </div>

      <div className="reviews-wrapper">
        <div className="header-review-wrapper">
          <img src={LockIcon} alt="lock icon" />
          <p>REVIEWS</p>
          <p>
            Begin interaction <br /> to see user's reviews
          </p>
        </div>

        <ListItemSecondary
          showLabel
          labelContent="Connect & Chat"
          showValue
          valueContent={detailsMock.rosesToConnect}
          showSeparator
        />
        <ListItemSecondary
          showLabel
          labelContent="Virtual Meetup"
          showValue
          valueContent={detailsMock.rosesToMeet}
        />
        <div className="footer-interaction-divider margin-vert-24" />
        <div className="buttons-interaction-container">
          <Button buttonStyle="primary" onClick={sendMessageHandler}>
            Send Message
          </Button>
          <ButtonIcon
            onClick={clickLikeHandler}
            buttonStyle={
              isProfileFavorite ? "heart-pink-gradient" : "heart-white-gradient"
            }
          />
        </div>
      </div>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      /> */}
    </div>
  );
};

export default ProducerInteractionPage;
