import "./styles.scss";
interface IChatTyping {
  className?: string;
}
const ChatTyping: React.FC<IChatTyping> = (props) => {
  const { className } = props;

  return (
    <div className={`chat-bubble ${className ? className : ""}`}>
      <div className="typing">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default ChatTyping;
