import { servicesApi } from "./fetchApi/services-api";

const DeleteMembershipUserApi = async (id: string) => {
  const apiRoute = `${servicesApi("mvpTests")}/super-delete/${id}`;
  try {
    const response = await fetch(apiRoute, {
      method: "DELETE",
    });

    // if (!response.ok) {
    //   throw new Error(`Network response was not ok: ${response.statusText}`);
    // }

    return response;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export default DeleteMembershipUserApi;
