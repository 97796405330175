import React, { ReactNode, useState } from "react";
import "./styles.scss";

interface ButtonNavigationProps {
  buttons: ButtonNavigationInfo[];
  className?: string;
}

export interface ButtonNavigationInfo {
  text: string;
  textRight?: string;
  clickAction: () => void;
  id: number;
  iconLeft?: string;
}

const ButtonNavigation: React.FC<ButtonNavigationProps> = (props) => {
  const { buttons, className } = props;
  const [actionedButton, setActionedButton] = useState(1);

  return (
    <div className={`action-button-group ${className ?? ""}`}>
      {buttons.map((btn, index) => (
        <button
          key={index}
          className={`action-button ${
            actionedButton === btn.id ? "active" : ""
          }`}
          onClick={() => {
            btn.clickAction();
            setActionedButton(btn.id);
          }}
        >
          {btn.iconLeft && (
            <span
              className={`icon icon-${btn.iconLeft} ${
                actionedButton === btn.id ? "" : "icon-gray"
              }  icon-sm icon-left`}
            />
          )}
          <p
            className={`no-margin ${actionedButton === btn.id ? "active" : ""}`}
          >
            {btn.text}
          </p>

          <p
            className={`no-margin right-text ${
              actionedButton === btn.id ? "active" : ""
            }`}
          >
            {btn.textRight}
          </p>
        </button>
      ))}
    </div>
  );
};

export default ButtonNavigation;
