import { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "../../components/PageTitles";
import ListItemLink from "../../components/Lists/ListItemLink";
import TextActionCard from "../../components/Cards/TextActionCard";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import { useIconContext } from "../../contexts/iconNavContext";

import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";

import accountIconGray from "../../assets/icons/gear-icon-gray.svg";
import linkIcon from "../../assets/icons/link-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import profileIconGray from "../../assets/icons/profile-icon-gray.svg";
import startGray from "../../assets/icons/star-icon-gray.svg";
import wallet from "../../assets/icons/wallet-icon-gray.svg";

const UserSettingsPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { userInfo, setUserInfo } = useUserInfo();

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      } else if (responseStatus?.status === 404) {
        setUserInfo("creator_application_status", "unsolicited");
      }
    };

    creatorApplyStatus();
  }, []);

  const becameACreatorHandler = () => {
    if (userInfo.creator_application_status === "PENDING") {
      setPrincipalScreen("/wait-list-application");
    } else {
      setPrincipalScreen("/create-legal-name");
    }
  };

  const userSettingsItems = [
    {
      labelContent: "Account",
      iconLeftContent: accountIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-account"),
      isActive: true,
    },
    {
      labelContent: "Profile",
      iconLeftContent: profileIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-profile"),
      isActive: true,
    },
    {
      labelContent: "My Wallet",
      iconLeftContent: wallet,
      clickAction: () => setPrincipalScreen("/user-settings-wallet"),
      isActive: true,
    },
    {
      labelContent: "Notifications",
      iconLeftContent: notificationsIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-notifications"),
      isActive: true,
    },
    {
      labelContent: "Subscription",
      iconLeftContent: startGray,
      clickAction: () => setPrincipalScreen("/user-settings-subscriptions"),
      isActive: true,
    },
    {
      labelContent: "Referrals",
      iconLeftContent: linkIcon,
      clickAction: () => setPrincipalScreen(""),
      isActive: userInfo.creator_application_status !== "unsolicited",
    },
    {
      labelContent: "My Offers",
      iconLeftContent: myOffersIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-myoffers"),
      isActive: true,
    },
  ];

  const pageBack = () => {
    setPrincipalScreen("");
    handleButtonClick("grid");
  };

  return (
    <div className="user-settings">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        onBackClick={pageBack}
        className="padding-hor-24"
        hasCloseIcon={windowSize >= 768}
      />

      <div className="list-container-settings margin-hor-24">
        {userSettingsItems
          .filter((item) => item.isActive)
          .map((item, index, arr) => (
            <ListItemLink
              key={index}
              onClick={item.clickAction}
              hasArrowRight
              imageLeft={item.iconLeftContent}
              isImageCircle
              highlightText={item.labelContent}
              hasSeparator={arr.length > index + 1}
            />
          ))}
        {userInfo.creator_application_status === "unsolicited" && (
          <TextActionCard
            className="margin-24"
            buttonClick={becameACreatorHandler}
            principalText="Start Earning (Monetize Content)"
            secondaryText={`Apply for a creator’s account and monetize your 
							exclusive content today.`}
            textButton="Become a Creator"
          />
        )}
      </div>
    </div>
  );
};

export default UserSettingsPage;
