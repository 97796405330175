import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import ListItemLink from "../../components/Lists/ListItemLink";

import { Chat } from "../../types/chatInfo";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import { useWebSocket } from "../../contexts/webSocketContext";
import { useChat } from "../../contexts/openedChatContext";
import { useIconContext } from "../../contexts/iconNavContext";

import getListUserChats from "../../api/chat/getListUserChats";
import getNotifications from "../../api/notifications/getNotifications";

import profileAvatar from "../../assets/icons/profile-icon-gray.svg";
import filterIcon from "../../assets/icons/iconfilter.svg";

const ChatListPage: React.FC = () => {
  const { getChats, addChatListener, removeListener } = useWebSocket();
  const { handleButtonClick } = useIconContext();
  const { userInfo } = useUserInfo();
  const { setTertiaryScreen, setSecondaryScreen, setPrincipalScreen } =
    useDeskNavigation();
  const { activeChatId, setActiveChatId } = useChat();
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState<string>("button1");
  const [chats, setChats] = useState<Chat[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<{
    [chatId: string]: number;
  }>({});
  const [notifications, setNotifications] = useState<any[]>([]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        const { chatId, isRead } = newMessage.payload;
        if (chatId !== activeChatId) {
          setUnreadMessagesCount((prev) => ({
            ...prev,
            [chatId]: !isRead ? (prev[chatId] || 0) + 1 : prev[chatId],
          }));
        }
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener, activeChatId]);

  const handleChat = (
    chatId: string,
    userId: string,
    unreadMessages: number
  ) => {
    setActiveChatId(chatId);

    const updatedUnreadMessagesCount = {
      ...unreadMessagesCount,
      [chatId]: 0,
    };

    setUnreadMessagesCount(updatedUnreadMessagesCount);

    setSecondaryScreen("/personal-chat", {
      chatId,
      userId,
      unreadCount: String(unreadMessages),
    });
  };

  const messageInChatRequest = async () => {
    const response = await getListUserChats(userInfo.access_token);

    if (response.status === 200) {
    } else {
      navigate("/login");
    }
  };

  const getListChats = useCallback(() => {
    getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    messageInChatRequest();
    getListChats();
  }, [getListChats, unreadMessagesCount]);

  const notificationsMock = [
    {
      title: "Mr. Grey",
      content: "Unlocked chat",
      type: "PAID_CONTENT",
    },
    {
      title: "Dream Man",
      content: "Sent you roses credit",
      type: "RECEIVED_ROSES",
    },
    {
      title: "Nick",
      content: "Added you to their favorites",
      type: "FAVORITED",
    },
    {
      title: "Aron",
      content: "Sent you a new message message",
      type: "SYSTEM",
    },
  ];

  const getListNotifications = useCallback(async () => {
    const request = await getNotifications(userInfo.access_token);

    if (request.status === 200) {
      setNotifications(request.res);
    } else {
      setNotifications(notificationsMock);
    }
  }, []);

  // PAID_CONTENT
  // RECEIVED_ROSES
  // FAVORITED
  // SYSTEM

  useEffect(() => {
    getListNotifications();
  }, [getListNotifications]);

  const messagePreview = (message: any) => {
    if (message?.content) {
      const content = message.content;
      return content.length > 50 ? `${content.substring(0, 40)}...` : content;
    }

    let content =
      message?.userId === userInfo.user_id
        ? "You send a new "
        : "You receive a new ";

    if (message?.files.length > 0) {
      switch (message.files[0].fileType) {
        case "PHOTO":
          content += "PHOTO";
          break;
        case "AUDIO":
          content += "AUDIO";
          break;
        default:
          break;
      }
    }

    return content;
  };

  const backPageHandler = () => {
    setPrincipalScreen("");
    setTertiaryScreen("");
    setSecondaryScreen("");
    setActiveChatId(null);
    handleButtonClick("grid");
  };

  const [searchValue, setSearch] = useState("");

  const getTextContent = (parent: HTMLDivElement, selector: string) =>
    parent.querySelector(selector)?.textContent?.toLocaleLowerCase() || "";

  const filterListBySearch = (text: string) => {
    const formattedSearch = text
      .toString()
      .toLocaleLowerCase()
      .trim()
      .split(/\s+/);

    listExtractsRef.current.forEach((item) => {
      if (!item) return;

      const name = getTextContent(item, "[data-highlight]");
      const lastMessage = getTextContent(item, "[data-simple]");

      const completeTerm = `${name} ${lastMessage}`;

      const match = formattedSearch.every((term) =>
        completeTerm.includes(term)
      );

      item.classList.toggle("show", match);
      item.classList.toggle("hidden", !match);
    });
  };

  const listExtractsRef = useRef<(HTMLDivElement | null)[]>([]);

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Messages", clickAction: () => setActiveSection("button1"), id: 1 },
    {
      text: "Notifications",
      clickAction: () => setActiveSection("button2"),
      id: 2,
    },
  ];

  return (
    <div className="chat-list margin-hor-24">
      <div className="top-container">
        <PageTitle
          title={"Messages"}
          subtitle={"Conversation"}
          onBackClick={backPageHandler}
          hasCloseIcon={windowSize >= 768}
        />
        <InputText
          value={searchValue}
          placeholder="Search Messages"
          onChangeInput={(text) => {
            setSearch(text);
            filterListBySearch(text);
          }}
          searchInput
          inputType="text"
          iconRight={filterIcon}
        />
        {/* 
          ! NOT DELETE comments is temporary
        <ButtonNavigation buttons={buttonsGroup} className="margin-vert-16" /> */}
      </div>

      {activeSection === "button1" && (
        <>
          <h3 className="chat-list__subtitle roboto-regular no-margin  margin-top-24">
            Messages
          </h3>
          {chats.map((chat, index, arr) => (
            <ListItemLink
              ref={(el) => (listExtractsRef.current[index] = el)}
              iconFill
              key={chat.chatId}
              highlightText={chat.chatParticipants[0]?.user.displayname}
              hasArrowRight
              isImageCircle
              simpleText={messagePreview(chat.messages[0]) || "2"}
              hasSeparator={arr.length > index + 1}
              imageLeft={
                chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  ? chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  : profileAvatar
              }
              onClick={() =>
                handleChat(
                  chat.chatId,
                  chat.chatParticipants[0]?.user.userId,
                  chat._count.messages
                )
              }
              rightNodeContent={
                <div
                  className="notification-count"
                  style={{
                    display:
                      chat._count.messages +
                        (unreadMessagesCount[chat.chatId] || 0) ===
                      0
                        ? "none"
                        : "flex",
                  }}
                >
                  <p className="notification-count__value">
                    {chat._count.messages +
                      (unreadMessagesCount[chat.chatId] || 0)}
                  </p>
                </div>
              }
            />
          ))}
        </>
      )}

      {activeSection === "button2" && (
        <>
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification) => {
                return (
                  <ListItemLink
                    imageLeft=""
                    highlightText={notification.title}
                    simpleText={notification.content}
                    hasArrowRight
                    onClick={function (a: any): void {
                      console.error("Function not implemented.");
                    }}
                  />
                );
              })}
            </>
          ) : (
            <p>You don't have any notifications yet!</p>
          )}
        </>
      )}
    </div>
  );
};

export default ChatListPage;
