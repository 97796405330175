import React, { ChangeEvent } from "react";
import "./styles.scss";

interface InputMessageProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  inputStyle?: string;
  value: string;
  onChangeInput: (a: string) => void;
  label?: string;
  isWarning?: boolean;
  maxLen?: number;
}

const InputMessage: React.FC<InputMessageProps> = (props) => {
  const {
    inputStyle,
    value,
    onChangeInput,
    label,
    maxLen,
    isWarning,
    ...rest
  } = props;

  const handlerInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    onChangeInput(inputValue);
  };

  return (
    <div id="input-message-default">
      {label && <label className="input">{label}</label>}

      <textarea
        maxLength={maxLen}
        className={`margin-top-4 ${inputStyle} ${isWarning ? "isWarning" : ""}`}
        value={value}
        onChange={handlerInput}
        {...rest}
      />
    </div>
  );
};

export default InputMessage;
