import React, { useEffect, useState } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import ButtonIcon from "../../Buttons/ButtonIcon";
import Category from "../../Category";
import ProfileDropDown from "./ProfileDropdown";

import ChevronDown from "../../../assets/icons/navigationIcons/chevron-down-white.svg";
import Verify from "../../../assets/icons/verify.svg";
import twitterIcon from "../../../assets/icons/buttonIcons/twitter-icon-gray.svg";
//import photoDefault from "../../../assets/images/testing/test-profile.png";
import photoDefault from "../../../assets/images/roses-photo-default.png";
import photo1 from "../../../assets/images/testing/teste1.png";
import photo2 from "../../../assets/images/testing/teste2.jpeg";
import photo3 from "../../../assets/images/testing/teste3.png";
import photo4 from "../../../assets/images/testing/teste4.png";
import photo5 from "../../../assets/images/testing/teste5.png";
import photo6 from "../../../assets/images/testing/teste6.png";
import photo7 from "../../../assets/images/testing/teste7.jpeg";
import photo8 from "../../../assets/images/testing/teste8.png";
//import video from "../../../assets/videos/video-profile-test.mp4";
import dollar from "../../../assets/icons/dollar-white.svg";
import dot from "../../../assets/icons/dots-h.svg";

import { useUserInfo } from "../../../hooks/userInfo";

import getPublicDetails from "../../../api/publicDetails/getProfileDetails";
import ImageCircleBorder from "../../ImageCircleBorder";

const userInterests = [
  "Sports",
  "Dancing",
  "White",
  "Fashion",
  "Travel",
  "Adventures",
];

interface ProfileDetailsProps {
  isVip: boolean;
  idProfile: string;
  principalPhoto: string;
  // rating: number;
  // isVerified: boolean;
  name: string;
  age: string | number;
  // profileDetails: string[];
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparentActive";
  icon: string;
  iconSecondary: string;
  changeNext?: (a: any) => void;
  changePrevious?: (a: any) => void;
  profilePhoto?: string;
  photos?: any[];
}

interface UserProfileDetails {
  gender: string;
  bio: string;
  height: number;
  location: {
    place: string | null;
    region: string | null;
    country: string | null;
  };
  ethnicity: string;
  nationality: string;
  languages: string[];
}

interface EssentialCard {
  icon: string;
  gender: string;
  info: string;
}

interface UserProfileDetails {
  gender: string;
  bio: string;
  height: number;
  location: {
    place: string | null;
    region: string | null;
    country: string | null;
  };
  ethnicity: string;
  nationality: string;
  languages: string[];
}

interface EssentialCard {
  icon: string;
  gender: string;
  info: string;
}

const NewProfile: React.FC<ProfileDetailsProps> = (props) => {
  const {
    isVip,
    idProfile,
    principalPhoto,
    // rating,
    name,
    age,
    // isVerified,
    // profileDetails,
    sendMessageClick,
    heartButtonClick,
    buttonStyle,
    icon,
    iconSecondary,
    changeNext,
    changePrevious,
    profilePhoto,
    photos,
  } = props;

  const { userInfo } = useUserInfo();

  const [userProfileDetails, setUserProfileDetails] =
    useState<UserProfileDetails | null>(null);

  const [essentialCards, setEssentialCards] = useState<EssentialCard[]>([]);

  const [photosPublic, setPhotosPublic] = useState("");
  const [videoPublic, setVideoPublic] = useState<string | null>();
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileDetails = await getPublicDetails(
          userInfo.access_token,
          idProfile
        );

        if (profileDetails.res.profile) {
          console.log(profileDetails.res.profile.cover, "videooooo");
          if (profileDetails.res.profile.cover) {
            setVideoPublic(profileDetails.res.profile.cover.url);
            console.log(videoPublic, "videooooo");
          }
          const { gender, height, ethnicity, nationality, languages } =
            profileDetails.res.profile;

          const cards: EssentialCard[] = [
            {
              icon: "icon-gender",
              gender: "Gender",
              info: gender || "Not informed",
            },
            {
              icon: "icon-earth",
              gender: "Nationality",
              info: nationality || "Not informed",
            },
            {
              icon: "icon-race",
              gender: "Race",
              info: "White, Latin",
            },
            {
              icon: "icon-ethnicity",
              gender: "Ethnicity",
              info: ethnicity || "Not informed",
            },
            {
              icon: "icon-measurement",
              gender: "Height",
              info: height || "Not informed",
            },
            {
              icon: "icon-gym",
              gender: "Body Style",
              info: "Slim, Athletic",
            },
            {
              icon: "icon-zodiac",
              gender: "Zodiac",
              info: "Sagittarius",
            },
            {
              icon: "icon-language",
              gender: "Languages",
              info:
                languages.length > 0
                  ? languages
                      .map(
                        (lang: string) =>
                          lang.charAt(0).toUpperCase() +
                          lang.slice(1).toLowerCase()
                      )
                      .join(", ")
                  : "Not informed",
            },
          ];

          setEssentialCards(cards);
          setUserProfileDetails(profileDetails.res.profile);
        }
      } catch (error) {
        console.error("Occurs a problem:", error);
      }
    };

    fetchUserProfile();
  }, [userInfo.access_token, idProfile]);

  return (
    <div id="profile">
      <div className="profile_dots">
        {/*  <img className="icon-dot" src={dot} alt="" /> */}
      </div>
      <div className="profile-banner">
        <div className="img-change-buttons">
          <div
            className="img-change-button-left"
            onClick={changePrevious}
          ></div>
          <div className="img-change-button-right" onClick={changeNext}></div>
          {principalPhoto.slice(-3) === "mp4" && (
            <video
              src={principalPhoto}
              loop
              muted
              playsInline
              disablePictureInPicture
              autoPlay={true}
              controls={false}
              preload="auto"
            />
          )}
          {principalPhoto.slice(-3) === "gif" && (
            <video
              src={principalPhoto}
              autoPlay={true}
              controls={false}
              preload="auto"
              loop
              muted
              playsInline
              disablePictureInPicture
            />
          )}

          {principalPhoto.slice(-3) !== "mp4" && principalPhoto !== null && (
            <img
              src={principalPhoto || photoDefault}
              alt="profile"
              loading="lazy"
            />
          )}
        </div>

        {/* {isVip && (
          <div className="vip-tag">
            <div className="background " />
            <p className="small-p no-margin">VIP</p>
          </div>
        )} */}

        <div className="profile-title">
          {/*  <div className="profile_dots">
            <img className="icon-dot" src={dot} alt="" />
          </div> */}
          <div className="user-info">
            <ImageCircleBorder
              centerImageAlt="profile avatar"
              centerImage={profilePhoto}
              size={56}
            />

            <div className="user-title">
              <p className="profile-info no-margin">{name}</p>
              {isVip && <span className="icon icon-burn icon-md"></span>}
            </div>
            <p className="counter">{age} years</p>
            {/* <Rating rating={rating} isVip={isVip} /> */}
            <div className="profile-statistics">
              <div className="statistics-info">
                <span className="icon icon-video icon-sm icon-gray"></span>
                <p className="counter">5</p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-photo icon-sm icon-gray"></span>
                <p className="counter">237</p>
              </div>

              <div className="statistics-info">
                <span className="icon icon-heart-solid icon-sm icon-gray"></span>
                <p className="counter">12.3k</p>
              </div>
            </div>
          </div>
          <div className="profile-banner-buttons">
            <ButtonIcon
              onClick={heartButtonClick}
              buttonStyle={buttonStyle}
              icon={icon}
            />
            <Button buttonStyle="primary" onClick={sendMessageClick}>
              SUBSCRIBE
            </Button>
            <ButtonIcon
              onClick={sendMessageClick}
              buttonStyle={"transparent"}
              icon={iconSecondary}
            />
          </div>
        </div>
      </div>
      <div className="profile-bio">
        <label>About Me</label>
        <p className="small-p no-margin">
          {userProfileDetails?.bio ? userProfileDetails.bio : "Not informed"}
        </p>
        {/* <div className="category-wrapper">
          {userInfo.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div> */}
        <label>Essentials</label>

        <div className="essentials-list">
          {essentialCards.map((card, index) => (
            <div className="essential-card" key={index}>
              <span
                className={`icon ${card.icon} icon-md icon-gray icon-round`}
              ></span>
              <div className="essential-wrapper">
                <label className="essential-label">{card.gender}</label>
                <p className="essential-info no-margin">
                  {Array.isArray(card.info) ? card.info.join(", ") : card.info}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="category-wrapper">
          {userLanguage.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div> */}
        <label>Media</label>
        <div className="media">
          <div className="media_scroll">
            <div className="media-photo">
              {photos?.map((photo, index) => (
                <img
                  key={index}
                  src={photo.url || photoDefault}
                  alt="profile"
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </div>

        <label>My Goals</label>
        <div className="my-goals">
          <div>
            <h3>$25,000</h3>
            <p>Saving for my condo</p>
            <div className="line_progress">
              <div className="line_progress_complete">
                {/*   <div className="line_indicator">
                  <div className="line_indicator_second"></div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="button">
              <img src={dollar} alt="" />
            </div>
          </div>
        </div>

        <label>Interests</label>
        <div className="category-wrapper ">
          {userInterests.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewProfile;
