import React from "react";
import "./styles.scss";
import { IMomentsUnseen } from "../../../types/moments";
import ImageCircleBorder from "../../ImageCircleBorder";

interface MomentsSpheresProps {
  clickMoment: (moment: string) => void;
  addMomentClick?: (a: any) => void;
  className?: string;
  momentsDetails: {
    momentsUnseen: IMomentsUnseen[];
    momentsSeen: IMomentsUnseen[];
  };
}

const MomentsSpheres: React.FC<MomentsSpheresProps> = (props) => {
  const { clickMoment, className, momentsDetails, addMomentClick } = props;

  return (
    <div
      id="moments-spheres-component"
      className={`padding-vert-16 ${className ?? ""}`}
    >
      {addMomentClick && (
        <div
          className="moments-spheres-component__add-moments-container"
          onClick={addMomentClick}
        >
          <span className="icon icon-plus icon-sm"></span>
        </div>
      )}

      {momentsDetails?.momentsUnseen.map((moment, index) => (
        <ImageCircleBorder
          hasPinkBorder
          key={index}
          onClick={() => clickMoment(moment.userId)}
          centerImageAlt="moment"
          centerImage={moment.profile.photos[0].thumbnailUrl}
        />
      ))}
      {momentsDetails?.momentsSeen.map((moment, index) => (
        <ImageCircleBorder
          key={index}
          onClick={() => clickMoment(moment.userId)}
          centerImageAlt="moment"
          centerImage={moment.profile.photos[0].thumbnailUrl}
        />
      ))}
    </div>
  );
};

export default MomentsSpheres;
