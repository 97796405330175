import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import getUsersListAll from "../../../api/getUsersListAll";
import { ICatalogResponse } from "../../../types/catalog";
import photoDefault from "../../../assets/images/roses-photo-default.png";
import { useUserInfo } from "../../../hooks/userInfo";

interface CardsCatalogProps {
  catalogHandlerCallback?: (a: any) => void;
  className?: string;
  catalogData: ICatalogResponse[];
  filterType?: "all" | "vip" | "non-vip" | "guys" | "top-creators" | "girls";
}

const CardsCatalog: React.FC<CardsCatalogProps> = (props) => {
  const {
    catalogHandlerCallback,
    className,
    filterType = "all",
    catalogData,
  } = props;
  const { userInfo } = useUserInfo();
  const [classWidth, setClassWidth] = useState("mobile-width");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [counters, setCounters] = useState<string[]>([]); //MOCK
  const [cardSizes, setCardSizes] = useState<string[]>([]);

  useEffect(() => {
    const sizesArrClass = Array.from(
      { length: gridSizeCardMasonry.length },
      () =>
        gridSizeCardMasonry[
          Math.floor(Math.random() * gridSizeCardMasonry.length)
        ]
    );

    setCardSizes(sizesArrClass);
  }, [catalogData]);

  const gridSizeCardMasonry = [
    "card-size-1-1",
    "card-size-1-2",
    "card-size-1-3",
    "card-size-2-1",
    "card-size-2-2",
    "card-size-2-3",
    "card-size-3-1",
    "card-size-3-2",
    "card-size-3-3",
  ];

  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const generateRandomNumber = (index: number) => {
      const randomNum =
        Math.floor(Math.random() * (20000 - 200 + 1)) + 200 + index * 100;
      return randomNum;
    };

    const formatCounter = (num: number) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num.toString();
    };

    const randomCounters = catalogData.map((_, index) => {
      const randomCounter = generateRandomNumber(index);
      return formatCounter(randomCounter);
    });

    setCounters(randomCounters);
  }, [catalogData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);

          if (size < 560) {
            setClassWidth("mobile-width");
          } else if (size >= 560 && size < 760) {
            setClassWidth("mobile-width-plus");
          } else if (size >= 760 && size < 1024) {
            setClassWidth("super-mobile-width");
          } else if (size >= 1024 && size < 1140) {
            setClassWidth("tablet-width");
          } else if (size >= 1140 && size < 1600) {
            setClassWidth("widescreen-width");
          } else {
            setClassWidth("hyper-widescreen-width");
          }
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const node = divRef.current;
    if (node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const filteredCatalogData = catalogData.filter((profileCard) => {
    if (filterType === "top-creators") {
      return profileCard.isVip;
    } else if (filterType === "girls") {
      return (
        profileCard?.profile?.gender?.toLowerCase() === "woman" ||
        profileCard?.profile?.gender === null
      );
    } else if (filterType === "guys") {
      return profileCard?.profile?.gender?.toLowerCase() === "man";
    }
    return true;
  });

  const sortedCatalogData = filteredCatalogData.sort((a, b) =>
    a.isVip === b.isVip ? 0 : a.isVip ? -1 : 1
  );

  const classNamesCatalog = [className ?? "", classWidth].join(" ").trim();

  return (
    <div id="catalog" ref={divRef} className={classNamesCatalog}>
      {sortedCatalogData?.map((profileCard, index) => (
        <div
          onClick={() => {
            if (catalogHandlerCallback) {
              catalogHandlerCallback(profileCard);
            }
          }}
          className={`profile-card ${
            !isHovered ? "remove-linear-gradient" : ""
          } ${cardSizes[index % cardSizes.length]}`}
          key={index}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <img
            src={
              hoveredIndex === index
                ? profileCard?.profile.photos[1]?.url
                : profileCard?.profile.photos[0]?.url || photoDefault
            }
            alt="catalog gallery"
            className="catalog-image"
          />

          <div className="profile-title">
            <div className="user-info">
              <div className="user-title">
                <p className="profile-info small no-margin">
                  {profileCard?.displayname}
                </p>
                {profileCard?.isVip && (
                  <span className="icon icon-burn icon-sm"></span>
                )}
              </div>

              <div className="user-stats">
                <p className="counter">{profileCard?.profile.age} years</p>
                <div className="followers-counter">
                  <span className="icon icon-heart-solid icon-sm icon-gray-light"></span>
                  <p className="counter">{counters[index]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsCatalog;
