import React, { ReactNode } from "react";
import "./styles.scss";

interface CategoryProps {
  children: ReactNode;
}

const Category: React.FC<CategoryProps> = (props) => {
  const { children } = props;
  return (
    <div
      id="category-default"
      className={`padding-hor-16 ${
        children === "Sports" || children === "Dancing"
          ? "active-class"
          : "disable-class"
      }`}
    >
      <p className="category__text">{children}</p>
    </div>
  );
};

export default Category;
