import { servicesApi } from "./fetchApi/services-api";

const MembershipUpdateStatusAdminApi = async (id: string) => {
  const apiRoute = `${servicesApi("mvpTests")}/${id}/update-approval`;

  try {
    const response = await fetch(apiRoute, {
      method: "PATCH",
    });
    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error(error);
  }
};

export default MembershipUpdateStatusAdminApi;
