import React from "react";
import "./styles.scss";

import ButtonIcon from "../../Buttons/ButtonIcon";

import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import { useIconContext } from "../../../contexts/iconNavContext";

import InputText from "../../Inputs/InputText";

import filterIcon from "../../../assets/icons/iconfilter.svg";

interface IHeaderMobileBottom {
  className?: string;
  inputSearch?: string;
  onChangeInput?: (a: string) => void;
}

const HeaderMobileBottom: React.FC<IHeaderMobileBottom> = (props) => {
  const { className, inputSearch, onChangeInput } = props;

  const { getIconClass, handleButtonClick } = useIconContext();
  const { setPrincipalScreen } = useDeskNavigation();

  return (
    <div className={`container_search ${className ? className : ""}`}>
      <div className="left-buttons-container">
        <ButtonIcon
          icon="icon-grid"
          buttonStyle="tertiary"
          iconClass={getIconClass("grid")}
          className="header-mobile-bottom-btn"
          onClick={() => {
            handleButtonClick("grid");
            setPrincipalScreen("");
          }}
        />
        <ButtonIcon
          icon="icon-heart-outline"
          buttonStyle="tertiary"
          iconClass={getIconClass("heart-outline")}
          className="header-mobile-bottom-btn"
          onClick={() => {
            handleButtonClick("heart-outline");
          }}
        />
      </div>
      <div className="container-input">
        <InputText
          value={inputSearch}
          searchInput
          onChangeInput={onChangeInput}
          placeholder="Search"
          inputStyle="input-text-search"
          iconRight={filterIcon}
          inputType="text"
        />
      </div>
    </div>
  );
};

export default HeaderMobileBottom;
