import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputText from "../../components/Inputs/InputText";
import { useUserInfo } from "../../hooks/userInfo";
import SubText from "../../components/Texts/SubText";
import TextActionCard from "../../components/Cards/TextActionCard";
import Button from "../../components/Buttons/Button";
import { IToast } from "../../types/toast";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import patchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import Toast from "../../components/Toast";
import { textCapitalize } from "../../utils/textCapitalize";

const UserSettingsEditProfilePage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameWarning, setUsernameWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [toastShow, setToastShow] = useState(false);

  useEffect(() => {
    getUserSelfDetailInit();
  }, []);

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setName(res.res.displayname);
      setUsername(res.res.username);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  const saveEditHandlerStorage = () => {
    setUserInfo("display_name", name);
    setUserInfo("legal_name", username);
  };

  const verifyAccountHandler = () => {};

  const saveEditHandler = async () => {
    const token = userInfo.access_token;

    /*   if (username === userInfo.legal_name) {
      openToast({
        title: "Your datas is updated",
        type: "info",
        description: "changes made successfully.",
      });
    } */

    // sendApiDisplayName(token, { display_name: name });
    const apiResUsername = await sendApiUsername(token, { username: username });
    const apiResName = await sendApiDisplayName(token, { displayname: name });

    if (apiResName === 200 && apiResUsername === 200) {
      openToast({
        title: "Changes successful",
        type: "success",
        description: "changes made successfully.",
      });
    }
  };

  const sendApiUsername = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200) {
      setUserInfo("legal_name", username);
      return 200;
    } else {
      if (username !== userInfo.legal_name) {
        setUsernameWarning(true);
        openToast({
          title: "Username alredy exists.",
          type: "error",
          description: "Set ohter username, and try again.",
        });
        setTimeout(() => {
          setUsernameWarning(false);
        }, 2000);
      } else if (name === userInfo.display_name) {
        openToast({
          title: "Your data is updated",
          type: "info",
          description: "changes made successfully.",
        });
      }
      return 500;
    }
  };

  const sendApiDisplayName = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200) {
      setUserInfo("display_name", name);
      return 200;
    }
    return 500;
  };

  const openToast = (object: {
    type: any;
    title: string;
    description: string;
  }) => {
    setToastConfig({
      title: object.title,
      type: object.type,
      description: object.description,
    });
    setToastShow(true);

    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        className="padding-left-16"
        onBackClick={() => setPrincipalScreen("/user-settings-profile")}
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputText
            label="Name"
            placeholder={
              userInfo.display_name ? userInfo.display_name : "Put your name"
            }
            inputType="text"
            value={name}
            isWarning={nameWarning}
            onChangeInput={(e) => {
              setName(e);
            }}
          />
          <InputText
            label="Username"
            placeholder={userInfo.legal_name ? username : "Put your username"}
            inputType="text"
            value={username}
            isWarning={usernameWarning}
            onChangeInput={(e) => {
              setUsername(e);
              // saveEditHandler();
            }}
          />
        </div>
        <SubText>
          In most cases, you’ll be able to change your name back to{" "}
          {userInfo.display_name}.roses for another 14 days.
        </SubText>
        <section className="profile-link-container margin-vert-24">
          <p className="profile-link-container__p no-margin">Profile</p>
          <p className="profile-link-container__p no-margin">
            https://roses.vip/
            <span>{username}</span>
          </p>
        </section>
        <TextActionCard
          buttonClick={verifyAccountHandler}
          principalText="Identity Verification"
          secondaryText="Let’s make sure nobody is trying to impersonate you."
          textButton="Verify Account"
        />
        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={async () => {
            saveEditHandler();
          }}
        >
          Done
        </Button>
      </main>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default UserSettingsEditProfilePage;
