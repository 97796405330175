import fetchApi from "../fetchApi";

const getChatMediaById = async (
  token: string, 
  chatId: string, 
  take?: number, 
  cursor?: string, 
  skip?: boolean
) => {
  return await fetchApi({
    service: "chat",
    endPoint: `/media?chatId=${chatId}${take ? `&take=${take}` : ''}${cursor ? `&cursor=${cursor}` : ''}${skip ? `&skip=${skip}` : ''}`,
    method: "GET",
    token,
  });
};

export default getChatMediaById;
