import React, { ReactNode, useState } from "react";
import "./styles.scss";

interface ListItemRadioProps {
  className?: string;
  highlightText?: string;
  simpleText?: string;
  imageLeft?: string;
  isImageCircle?: boolean;
  hasSeparator?: boolean;
  radioId: string;
  groupName: string;
  inputValue: string;
  isChecked?: boolean;
  rightNodeContent?: ReactNode;
  checkValueCallback: (event: string) => void;
  defaultValue?: string;
}

const ListItemRadio: React.FC<ListItemRadioProps> = (props) => {
  const {
    highlightText,
    imageLeft,
    isImageCircle,
    simpleText,
    className,
    hasSeparator,
    groupName,
    inputValue,
    radioId,
    isChecked,
    rightNodeContent,
    checkValueCallback,
    defaultValue,
  } = props;

  const [isCheck, setIsCheck] = useState(inputValue === defaultValue);
  const listItemClass = () => {
    if (imageLeft && !rightNodeContent) {
      return "column-for-image";
    } else if (imageLeft && rightNodeContent) {
      return "column-for-image-and-node";
    } else if (!imageLeft && rightNodeContent) {
      return "column-for-node";
    } else {
      return "";
    }
  };
  return (
    <label id={`list-item-radio`}>
      <div
        id={`list-item-radio`}
        className={`${className ?? ""} ${listItemClass()}`}
      >
        {imageLeft && (
          <div
            className={`item__image-left-container ${
              isImageCircle ? "image-circle" : ""
            }`}
          >
            <img src={imageLeft} alt="left icon" />
          </div>
        )}

        <div className="item__main-container">
          <p className="main-container__highlight-text no-margin">
            {highlightText}
          </p>
          <p className="main-container__simple-text no-margin">{simpleText}</p>
        </div>
        {rightNodeContent && (
          <section className="list-item-radio__right-node">
            {rightNodeContent}
          </section>
        )}

        <div className="radio-input-container">
          <label className=" radio-input-container__label padding-vert-16 no-margin">
            <input
              id={radioId}
              name={groupName}
              value={inputValue}
              type="radio"
              onChange={() => {
                checkValueCallback(inputValue);
              }}
              className="radio-input-container__input"
              defaultChecked={isChecked}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        {hasSeparator && <div className="separator" />}
      </div>
    </label>
  );
};

export default ListItemRadio;
