const iconList = [
  "icon-add",
  "icon-airplane",
  "icon-apenotic-avatar",
  "icon-apenotic",
  "icon-apple",
  "icon-arrow-down",
  "icon-arrow-left",
  "icon-arrow-right",
  "icon-arrow-up",
  "icon-auction",
  "icon-bank",
  "icon-birth",
  "icon-blocked",
  "icon-bookmark",
  "icon-brush",
  "icon-bulk-images",
  "icon-burn",
  "icon-buy",
  "icon-calendar-add",
  "icon-camera",
  "icon-camp",
  "icon-cancel",
  "icon-Cardano",
  "icon-certified-1",
  "icon-certified-path1",
  "icon-certified-path2",
  "icon-chain",
  "icon-check",
  "icon-chevron-bottom",
  "icon-chevron-double-left",
  "icon-chevron-double-right",
  "icon-chevron-left",
  "icon-chevron-right",
  "icon-chevron-top",
  "icon-circle",
  "icon-clock",
  "icon-close",
  "icon-cloud",
  "icon-code-1",
  "icon-code",
  "icon-collectors",
  "icon-connection",
  "icon-contract",
  "icon-copy",
  "icon-credit-card",
  "icon-cube",
  "icon-dashboard",
  "icon-deferred",
  "icon-detail",
  "icon-digitalprint",
  "icon-discord",
  "icon-dollar",
  "icon-dots-h",
  "icon-dots-v",
  "icon-dribble",
  "icon-drink",
  "icon-earth",
  "icon-edit",
  "icon-ethereum",
  "icon-ethnicity",
  "icon-events",
  "icon-eye",
  "icon-face-id-confirmed",
  "icon-face-id",
  "icon-filter",
  "icon-fingerprint",
  "icon-fixed",
  "icon-flag",
  "icon-folder",
  "icon-food-1",
  "icon-food-2",
  "icon-food",
  "icon-games",
  "icon-gas",
  "icon-gear",
  "icon-gender",
  "icon-gifts",
  "icon-globe",
  "icon-google-play-path1",
  "icon-google-play-path2",
  "icon-google-play-path3",
  "icon-google-play-path4",
  "icon-Google-path1",
  "icon-Google-path2",
  "icon-Google-path3",
  "icon-Google-path4",
  "icon-grid",
  "icon-gym",
  "icon-hair-1",
  "icon-hair-2",
  "icon-hair",
  "icon-heart-outline",
  "icon-heart-solid",
  "icon-height",
  "icon-hiking",
  "icon-home",
  "icon-id",
  "icon-info",
  "icon-instagram",
  "icon-key",
  "icon-keypad",
  "icon-language",
  "icon-layers",
  "icon-link",
  "icon-linkedin",
  "icon-loan",
  "icon-location",
  "icon-lock",
  "icon-log-in",
  "icon-log-out",
  "icon-mail",
  "icon-makeup-1",
  "icon-makeup-2",
  "icon-makeup",
  "icon-marketplace",
  "icon-match",
  "icon-measurement-1",
  "icon-measurement-2",
  "icon-measurement",
  "icon-menu",
  "icon-messages",
  "icon-mic",
  "icon-mint",
  "icon-minus",
  "icon-mobile-ring",
  "icon-mobile",
  "icon-money",
  "icon-moon",
  "icon-move",
  "icon-music",
  "icon-nft-single",
  "icon-nft",
  "icon-notebook",
  "icon-notes",
  "icon-notification",
  "icon-notifiication-add",
  "icon-ownership",
  "icon-passanger",
  "icon-passport-1",
  "icon-passport",
  "icon-patreon",
  "icon-percent",
  "icon-perfume",
  "icon-phone",
  "icon-photo",
  "icon-play",
  "icon-plus",
  "icon-private",
  "icon-public",
  "icon-puzzle",
  "icon-qr-code",
  "icon-qr-scanner",
  "icon-question",
  "icon-race",
  "icon-refresh",
  "icon-reorder",
  "icon-retweet",
  "icon-revenue",
  "icon-roadmap",
  "icon-robot",
  "icon-roses-default-path1",
  "icon-roses-default-path2",
  "icon-roses-outline",
  "icon-roses-solid",
  "icon-search",
  "icon-security",
  "icon-selfie",
  "icon-send-1",
  "icon-send",
  "icon-share-1",
  "icon-share",
  "icon-smile",
  "icon-solana",
  "icon-sport",
  "icon-star-outline",
  "icon-star-solid",
  "icon-stopwatch",
  "icon-text",
  "icon-theatre",
  "icon-tickets",
  "icon-tiktok",
  "icon-tips",
  "icon-transfer",
  "icon-trash",
  "icon-twitter",
  "icon-underwear",
  "icon-unlock",
  "icon-upload-bulk",
  "icon-upload-1",
  "icon-upload",
  "icon-user",
  "icon-verified-1",
  "icon-verified-path1",
  "icon-verified-path2",
  "icon-video",
  "icon-wallet",
  "icon-weight-1",
  "icon-weight-2",
  "icon-weight",
  "icon-whitelist",
  "icon-wi-fi-off",
  "icon-wi-fi-on",
  "icon-zodiac",
];

export default iconList;
