import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import Desk from "../../components/Wrappers/Desk";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { ICatalogResponse } from "../../types/catalog";
import { useUserInfo } from "../../hooks/userInfo";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { useNavigate } from "react-router-dom";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import ModalInitialPreferences from "../../components/Modals/ModalInitialPreferences";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getListUserChats from "../../api/chat/getListUserChats";
import { Chat, INewMessage } from "../../types/chatInfo";
import {
  IMoments,
  IMomentsUnseen,
  MomentsDetails,
  MomentsDetailsResponse,
} from "../../types/moments";
import getListFavorites from "../../api/favorite/getListFavorites";
import { useWebSocket } from "../../contexts/webSocketContext";
import newMessageNotification from "../../assets/sounds/notification-01.MP3";
import { useChat } from "../../contexts/openedChatContext";
import getAllMoments from "../../api/moments/getAllMoments";
import AddMediaPage from "../AddMediaPage";
import { useModal } from "../../hooks/useModal";
import MomentsTrack from "../../components/Moments/MomentsTrack";
import getMomentsUnseen from "../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../api/moments/getMomentsSeen";
import LoadingPage from "../../components/LoadingPage";
import FeedPage from "../Feed";
import getUsersListAll from "../../api/getUsersListAll";

interface GroupedMoments {
  [key: string]: IMoments[];
}

const Homepage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();
  const navigate = useNavigate();
  const { isChatPageOpen } = useContext(ToggleChatOpenContext);
  const { addChatListener, removeListener } = useWebSocket();
  const { activeChatId } = useChat();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    componentFeed,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const [profileAvatar, setProfileAvatar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasANewMessage, setHasANewMessage] = useState(false);
  const [momentsUnseen, setMomentsUnseen] = useState<IMomentsUnseen[] | []>([]);
  const [momentsSeen, setMomentsSeen] = useState<IMomentsUnseen[] | []>([]);
  const [isMediaLoadedInMoments, setIsMediaLoadedInMoments] = useState(false);
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);

  useEffect(() => {
    const getCatalogData = async (userToken: string) => {
      let response = await getUsersListAll(userToken);

      switch (response?.status) {
        case 200:
          setCatalogData(response.res);
          console.log(response.res);
          break;
        default:
          break;
      }
    };

    getCatalogData(userInfo.access_token);
  }, []);

  useEffect(() => {
    const handleNewMessage = (newMessage: INewMessage) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        if (
          newMessage?.payload?.user?.userId !== userInfo.user_id &&
          newMessage.payload.chatId !== activeChatId
        ) {
          playNotificationSound();
          setHasANewMessage(true);
        }
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener, activeChatId, userInfo]);

  const playNotificationSound = () => {
    const audio = new Audio(newMessageNotification);
    audio.play().catch((error) => {
      console.error("Error trying to play the audio:", error);
    });
  };

  useEffect(() => {
    const getUserInfoRequest = async () => {
      const response = await getUserInfo(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/login");
          } else {
            setProfileAvatar(response.res?.photos[0]?.url);
          }
          break;
        case 401:
          navigate("/login");

          break;

        default:
          navigate("/login");

          break;
      }
    };

    const messageInChatRequest = async () => {
      const response = await getListUserChats(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/login");
          } else {
            if (
              response?.res?.filter((chat: Chat) => chat?._count.messages !== 0)
                .length > 0
            ) {
              setHasANewMessage(true);
            } else {
              setHasANewMessage(false);
            }
          }
          break;

        case 401:
          navigate("/login");

          break;

        default:
          navigate("/login");

          break;
      }
    };

    const favoriteCreatorsAndMoments = async () => {
      const resFavorites = await getListFavorites(userInfo.access_token);
      const resMomentsUnseen = await getMomentsUnseen(
        userInfo.access_token,
        10
      );
      const resMomentsSeen = await getMomentSeen(userInfo.access_token, 10);

      switch (resFavorites.status) {
        case 200:
          if (resFavorites?.res?.message?.statusCode === 401) {
            navigate("/login");
          } else {
            setMomentsUnseen(resMomentsUnseen.res);
            setMomentsSeen(resMomentsSeen.res);
          }

          break;

        case 401:
          navigate("/login");

          break;

        default:
          navigate("/login");

          break;
      }
    };

    favoriteCreatorsAndMoments();
    getUserInfoRequest();
    messageInChatRequest();
  }, [
    userInfo,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    addChatListener,
    navigate,
    hasANewMessage,
  ]);

  useEffect(() => {
    const requestUserPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response.res.preferedGenders?.length === 0) {
            setIsModalOpen(true);
          }
          break;
        case 401:
          navigate("/login");

          break;

        default:
          navigate("/login");

          break;
      }
    };
    requestUserPreferences();
  }, []);

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    if (isChatPageOpen) {
      setTimeout(() => {
        setSecondaryScreen("/personal-chat", { userId: profile.userId });
      }, 0);
    } else {
      setSecondaryScreen("");
    }
    setTertiaryScreen("");
    setPrincipalScreen("/interaction", { userId: profile.userId });
  };

  const clickProfileAvatar = () => {
    if (componentFeed) {
      setPrincipalScreen("/user-settings");
      setFeedScreen("");
      setTertiaryScreen("");
      setSecondaryScreen("");
    } else {
      setTertiaryScreen("");
      setSecondaryScreen("");
      setPrincipalScreen("/user-settings");
    }
  };

  const onChangeInputSearch = () => {};

  const clickMoment = (userId: string) => {
    setModalContent(
      <MomentsTrack
        userIdToStarted={userId}
        allUnseenUserIds={momentsUnseen.map((moment) => moment.userId)}
        allSeenUserIds={momentsSeen.map((moment) => moment.userId)}
      />
    );

    setOpenUseModal(true);
  };

  const favoritesClickHandler = () => {
    setTertiaryScreen("");
    setSecondaryScreen("");
    setPrincipalScreen("");
    setFeedScreen("");
  };

  const addMomentHandler = () => {
    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };
  const openChatListHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("/chat-list");
      setFeedScreen("");
      setTertiaryScreen("");
      setSecondaryScreen("");
    } else {
      setSecondaryScreen("");
      setTertiaryScreen("");
      setPrincipalScreen("/chat-list");
    }
  };

  return (
    <>
      <Desk
        clickProfileAvatar={clickProfileAvatar}
        profileAvatar={profileAvatar}
        catalogHandlerCallback={catalogHandlerCallback}
        clickMoment={clickMoment}
        principalMain={componentPrincipal}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        feedMain={componentFeed}
        hasANewMessage={hasANewMessage}
        onChangeInputSearch={onChangeInputSearch}
        valueInputSearch=""
        catalogMain={mainScreen}
        favoritesClickHandler={favoritesClickHandler}
        momentsUnseen={momentsUnseen}
        addMomentClick={addMomentHandler}
        momentsSeen={momentsSeen}
        clickChatList={openChatListHandler}
        catalogData={catalogData}
      />
      {isModalOpen && (
        <ModalInitialPreferences
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

export default Homepage;
