export const keyWords = [
  "perfect",
  "flawless",
  "sexy",
  "see",
  "photos",
  "photo",
  "shoot",
  "shot",
  "hot",
  "pics",
  "pic",
  "send",
  "beautiful",
  "stunning",
  "gorgeous",
  "angel",
  "goddess",
  "love",
  "favorite",
];
