import { servicesApi } from "../fetchApi/services-api";

const deleteProfilePhotos = async (token: string, id: string) => {
  const apiRoute = `${servicesApi("user")}/profile-photo/${id}`;
  try {
    const response = await fetch(apiRoute, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "access-control-allow-origin": "*",
      },
    });

    // if (!response.ok) {
    //   throw new Error(`Network response was not ok: ${response.statusText}`);
    // }

    const text = await response.text();
    const res = text ? JSON.parse(text) : null;

    return { res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export default deleteProfilePhotos;
