import { useEffect, useState } from "react";

const useScrollPosition = (ref: { current: any }) => {
  const [isAtTop, setIsAtTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const TOLERANCE = 2;

  const handleScroll = () => {
    const container = ref.current;

    if (!container) return;

    const position = container.scrollTop;
    const totalHeight = container.scrollHeight;
    const visibleHeight = container.clientHeight;

    setScrollPosition(position);
    setIsAtTop(position === 0);
    setIsAtBottom(position + visibleHeight >= totalHeight - TOLERANCE);
  };

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref]);

  return { isAtTop, isAtBottom, scrollPosition };
};

export { useScrollPosition };
