import { useUserInfo } from "./userInfo";
import postSendRoses from "../api/rosesTransactions/postSendRoses";
import { useAvailableRoses } from "../contexts/availableRosesContext";
import { useProducerDetails } from "../contexts/producerDetailsContext";

const useCanPurchasedMedia = () => {
  const { userInfo } = useUserInfo();
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { producerDetails } = useProducerDetails();

  /**
   * TODO: Refactor this method when possibly
   **/
  const canPurchase = async (price?: number): Promise<boolean> => {
    console.log("Preção: ", price);
    if (price && availableRoses >= price) {
      updateSubtractAvailableRoses(price);
      await postSendRoses(
        userInfo.access_token,
        producerDetails.userId,
        price,
        "Unlocked Chat/Media/Contents"
      );
      return true;
    }
    return false;
  };
  return { canPurchase, availableRoses };
};

export { useCanPurchasedMedia };
