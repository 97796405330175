import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import CloseIconWhite from "../../assets/icons/closeIcons/close-icon-white.svg";
import { useModal } from "../../hooks/useModal";
import { thumbnailGenerator } from "../../utils/thumbnailGenerator";
import InputText from "../../components/Inputs/InputText";
import PlusIconGray from "../../assets/icons/more-icon-gray.svg";
import ArrowRightPink from "../../assets/icons/arrow-right-pink.svg";
import IconFilterGray from "../../assets/icons/iconfilter.svg";
import DigitalPrintIconPink from "../../assets/icons/digital-print-icon-pink.svg";
import { useWindowWidthSize } from "../../hooks/useWindowWidthSize";
import PageTitle from "../../components/PageTitles";
import RosesLogoIconPink from "../../assets/icons/logos/logo-rose-icon-pink.svg";
import DollarIconGray from "../../assets/icons/dollar-icon-gray.svg";
import ProfileSimpleIconGray from "../../assets/icons/profile-simple-icon-gray.svg";
import BalloonIconGray from "../../assets/icons/ballon-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import FeedPlusIconGray from "../../assets/icons/feed-plus-icon-gray.svg";
import ClockIconGray from "../../assets/icons/clock-icon-gray.svg";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";
import { usePublishContent } from "../../hooks/usePublishContent";
import PlayIconWhite from "../../assets/icons/play-circle-white.svg";
import { useWebSocket } from "../../contexts/webSocketContext";
import { Chat } from "../../types/chatInfo";
import postUploadMedia from "../../api/chat/postUploadMedia";
import postPost, { PostBodyRequest } from "../../api/posts/postPost";
import { useUserInfo } from "../../hooks/userInfo";
import MultiImage from "../../assets/icons/multi-photo-pink.svg";
import postMoment, { IPostMomentBody } from "../../api/moments/postMoment";
import LoadingPage from "../../components/LoadingPage";
import {
  base64OrBlobToFile,
  handleUploadMedia,
} from "../../utils/base64OrBlobToFile";
import { startInterval, stopInterval } from "../../utils/timer";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";
import ListItemLink from "../../components/Lists/ListItemLink";
import ListItemRadio from "../../components/Lists/ListItemRadio";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import GridIconPink from "../../assets/icons/grid-square-pink.svg";

type ICapturedMedia = {
  type: "video" | "photo";
  media: string;
  thumbnail?: string;
};

type Consumer = {
  id: string;
  image: string;
  name: string;
};

interface AddMediaPageProps {
  previousPage?: "none" | "chat" | "home";
  chatIdToSendContent?: string;
  onUploadComplete?: () => void;
}

const AddMediaPage: React.FC<AddMediaPageProps> = (props) => {
  const {
    previousPage = "none",
    chatIdToSendContent,
    onUploadComplete,
  } = props;

  const { setCreatorContent } = usePublishContent();
  const { setOpenUseModal, setModalContent } = useModal();
  const { windowSize } = useWindowWidthSize();
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordingButtonRef = useRef<HTMLButtonElement>(null);
  const videoCapturedRef = useRef<HTMLVideoElement>(null);

  let holdTimeout: NodeJS.Timeout;
  let recordingInterval: NodeJS.Timeout;

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [showMedia, setShowMedia] = useState(false);
  const [mediaToShow, setMediaToShow] = useState<ICapturedMedia | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [capturedMedia, setCapturedMedia] = useState<ICapturedMedia[] | []>([]);
  const [isHolding, setIsHolding] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [showPixelShieldPill, setShowPixelShieldPill] = useState(false);
  const [rosesAmount, setRosesAmount] = useState(0);
  const [isExclusiveContent, setIsExclusiveContent] = useState(false);
  const [shareContentLocation, setShareContentLocation] = useState("");
  const [consumerList, setConsumerList] = useState<Consumer[]>([]);
  const [showCamera, setShowCamera] = useState(true);
  const [showConfig, setShowConfig] = useState(false);
  const [showExclusiveList, setShowExclusiveList] = useState(false);
  const [chatIdsForSendContent, setChatIdsForSendContent] = useState<string[]>(
    []
  );
  const [filteredConsumerList, setFilteredConsumerList] = useState<Consumer[]>(
    []
  );
  const [inputSearchConsumer, setInputSearchConsumer] = useState<string>("");
  const [collaboratorAdded, setCollaboratorAdded] = useState<string[]>([]);
  const [videoRecordingTime, setVideoRecordingTime] = useState("0:00");
  const [isPlayerIconShow, setIsPlayerIconShow] = useState(true);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  useEffect(() => {
    startCamera();
    getListChats();
    if (chatIdToSendContent) {
      setChatIdsForSendContent((prev) => [...prev, chatIdToSendContent]);
    }
  }, []);

  useEffect(() => {
    if (windowSize > 700) {
      setShowCamera(true);

      if (!showExclusiveList) {
        setShowConfig(true);
      } else {
        setShowConfig(false);
      }
    } else {
      setShowConfig(false);
      setShowExclusiveList(false);
    }
  }, [windowSize]);

  const getListChats = useCallback(() => {
    getChats({}, (res: Chat[]) => {
      const prepareList = res.map((chat: Chat) => {
        return {
          id: chat.chatId,
          image: chat.chatParticipants[0]?.user?.profile.photos[0]?.url,
          name: chat.chatParticipants[0]?.user?.displayname,
        };
      });

      setConsumerList(prepareList);
      setFilteredConsumerList(prepareList);
    });
  }, [getChats]);

  const closeModal = () => {
    stopRecording();
    stopStream();
    setShowCamera(false);
    setShowConfig(false);
    setShowExclusiveList(false);
    setOpenUseModal(false);
  };

  const startCamera = async () => {
    setShowMedia(false);
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(stream);

        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.muted = true;
          videoRef.current.play();
        }
      } else {
        setError("Error");
      }
    } catch (err) {
      setError((err as Error).message);
    }
  };

  const handleMouseDown = () => {
    setIsHolding(false);

    holdTimeout = setTimeout(() => {
      setIsHolding(true);
      startRecording();
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimeout);
    if (!isHolding) {
      capturePhoto();
    } else {
      stopRecording();
      setIsHolding(false);
    }
    stopStream();
  };

  const handleMouseLeave = () => {
    clearTimeout(holdTimeout);
    if (isHolding) {
      stopRecording();
      setIsHolding(false);
    }
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const imageDataURL = canvasRef.current.toDataURL("image/png");
        setMediaToShow({ media: imageDataURL, type: "photo" });

        setCapturedMedia((prev) => [
          ...prev,
          { media: imageDataURL, type: "photo" },
        ]);
        setShowMedia(true);
      }
    }
  };

  const startRecording = () => {
    if (stream) {
      try {
        const options = { mimeType: "video/webm; codecs=vp8" };

        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options.mimeType = "video/webm";
        }

        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        const localChunks: Blob[] = [];
        mediaRecorder.start();
        setIsRecording(true);
        recordingInterval = startInterval(setVideoRecordingTime);

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localChunks.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          if (localChunks.length > 0) {
            const blob = new Blob(localChunks, { type: "video/webm" });

            const videoURL = URL.createObjectURL(blob);
            setMediaToShow({ media: videoURL, type: "video" });
            setRecordedChunks([]);

            thumbnailGenerator(videoURL).then((thumbnail) => {
              setCapturedMedia((prev) => [
                ...prev,
                { media: videoURL, thumbnail, type: "video" },
              ]);
            });
            setIsRecording(false);

            stopInterval(recordingInterval, setVideoRecordingTime);
            setShowMedia(true);
          }
        };

        mediaRecorder.onerror = (error) => {
          console.error("Error: ", error);
        };
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      videoRef.current?.pause();
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const pixelShieldFilterHandler = () => {
    setShowPixelShieldPill((prev) => !prev);
  };

  const shareContentSettings = () => {
    setShowCamera(false);
    setShowConfig(true);
  };

  const cancelShareHandler = () => {
    setOpenUseModal(false);
  };

  const shareContentBackButtonHandler = () => {
    setShowCamera(true);
    setShowConfig(false);
  };

  const shareWihAllHandler = () => {
    setChatIdsForSendContent(consumerList.map((consumer) => consumer.id));
  };

  const removeAllIdsToSend = () => {
    setChatIdsForSendContent([]);
  };

  const finishSelectionHandler = () => {
    setShowExclusiveList(false);
    setShowConfig(true);
  };

  const privateListBackButton = () => {
    setShowExclusiveList(false);
    setShowConfig(true);
  };

  const addConsumerInListToSend = (id: string) => {
    if (id && chatIdsForSendContent.includes(id)) {
      const newList = chatIdsForSendContent.filter((list) => list !== id);
      setChatIdsForSendContent(newList);
    } else {
      setChatIdsForSendContent((prev) => [...prev, id]);
    }
  };

  const playPauseVideo = () => {
    if (videoCapturedRef.current?.paused) {
      videoCapturedRef.current.play();
      setIsPlayerIconShow(false);
    } else {
      videoCapturedRef.current?.pause();
      setIsPlayerIconShow(true);
    }
  };

  const removeMediaFromSharing = (media: ICapturedMedia) => {
    const newMediaList = capturedMedia.filter((file) => file !== media);
    setCapturedMedia(newMediaList);

    if (newMediaList.length === 0) {
      setShowMedia(false);
      startCamera();
    }
  };

  const addRosesInputHandler = (e: string) => {
    const inputValue = e.replace("Roses: ", "");
    const numericValue = inputValue.replace(/\D/g, "");
    setRosesAmount(Number(numericValue));
  };

  const searchInConsumerList = (e: string) => {
    const newList =
      consumerList?.filter((consumer) =>
        consumer.name.toLowerCase().includes(e.toLowerCase())
      ) || [];
    setInputSearchConsumer(e);

    setFilteredConsumerList(newList);
  };

  const shareContentHandler = async () => {
    if (capturedMedia.length === 0) {
      setToastConfig({
        title: "Oops...",
        description: "There is no content to send",
        type: "info",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);

      return;
    }

    // uploadFilesToChat();

    //	The lines below will be added as the code evolves

    // if (!shareContentLocation || chatIdsForSendContent.length === 0) {
    //   setToastConfig({
    //     title: "Oops...",
    //     description:
    //       "You have not added a location where your content should be sent.",
    //     type: "info",
    //   });

    //   setToastShow(true);

    //   setTimeout(() => {
    //     setToastShow(false);
    //   }, 5000);

    //   return;
    // }

    setModalContent(<LoadingPage />);
    if (shareContentLocation === "feed") {
      await uploadFileInFeed();
    }

    if (shareContentLocation === "moment") {
      await uploadFileInMoment();
    }

    if (chatIdsForSendContent.length > 0) {
      await uploadFilesToChat();
    }

    setCreatorContent({
      collaborators: [],
      isExclusiveContent,
      listConsumer: chatIdsForSendContent,
      medias: [],
      message: descriptionContent,
      rosesContentPrice: rosesAmount,
      shareLocation: shareContentLocation,
    });

    stopStream();
    closeModal();
  };

  const uploadFileInFeed = async () => {
    try {
      const files = await Promise.all(
        capturedMedia.map((file, index) =>
          base64OrBlobToFile(file.media, `${userInfo.display_name}-${index}`)
        )
      );

      const validFiles = files.filter((file): file is File => file !== null);
      if (validFiles.length === 0) {
        throw new Error("error: validFiles.length === 0");
      }

      const prepareData: PostBodyRequest = {
        files: validFiles,
        price: isExclusiveContent ? rosesAmount : 0,
        content: descriptionContent,
      };

      const request = await postPost(userInfo.access_token, prepareData);

      switch (request.status) {
        case 201:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFileInMoment = async () => {
    try {
      const files = await handleUploadMedia(
        capturedMedia.map((file) => file.media)
      );

      const prepareData: IPostMomentBody = {
        file: files,
        price: isExclusiveContent ? rosesAmount : 0,
      };

      const request = await postMoment(userInfo.access_token, prepareData);

      switch (request?.status) {
        case 201:
          break;
        default:
          console.error("Failed to create moment:", request?.res);
          break;
      }
    } catch (error) {
      console.error("Error uploading moment:", error);
    }
  };

  const uploadFilesToChat = async () => {
    try {
      const files = await handleUploadMedia(
        capturedMedia.map((file) => file.media)
      );
      const uploadPromises = chatIdsForSendContent.map((chatId) => {
        return postUploadMedia(userInfo.access_token, {
          chatId,
          content: descriptionContent,
          price: isExclusiveContent ? rosesAmount : 0,
          paidContent: isExclusiveContent,
          files,
        });
      });
      await Promise.all(uploadPromises);
      if (onUploadComplete) {
        setTimeout(() => {
          onUploadComplete();
        }, 100);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const actionsList = () => {
    return (
      <>
        <ListItemLink
          highlightText="Add Collaborator"
          simpleText="Increase visibility"
          hasSeparator
          onClick={() => {}}
          hasArrowRight
          imageLeft={ProfileSimpleIconGray}
          isImageCircle
        />
        {previousPage === "home" ? (
          <>
            <ListItemRadio
              groupName="sharing-location"
              checkValueCallback={setShareContentLocation}
              inputValue="moment"
              hasSeparator
              isChecked={shareContentLocation === "moment"}
              highlightText="Your moment"
              simpleText="Share with everyone"
              imageLeft={ClockIconGray}
              radioId="moment-location-share"
              isImageCircle
            />
            <ListItemRadio
              groupName="sharing-location"
              checkValueCallback={setShareContentLocation}
              inputValue="feed"
              hasSeparator
              highlightText="Your feed"
              imageLeft={FeedPlusIconGray}
              isChecked={shareContentLocation === "feed"}
              radioId="feed-location-share"
              isImageCircle
              simpleText="Share with everyone"
            />
            <ListItemLink
              highlightText="Message"
              simpleText="Share privately to people"
              onClick={() => {
                setShowConfig(false);
                setShowExclusiveList(true);
              }}
              hasArrowRight
              imageLeft={BalloonIconGray}
              isImageCircle
              // hasSeparator
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const openCurrentGalleryHandler = () => {
    setShowMedia(true);
  };
  const openPersonalGalleryHandler = () => {};

  return (
    <div className="add-media-page">
      <nav className="add-media-page__navbar">
        <p className="no-margin">Share Content</p>
        <img
          src={CloseIconWhite}
          alt="close icon"
          className="image-container__close-icon"
          onClick={closeModal}
        />
      </nav>
      {showCamera ? (
        <section className="media-selection-config">
          <section className="media-selection-config__image-container">
            <button
              className="image-container__pixel-shield-button"
              onClick={pixelShieldFilterHandler}
            >
              <img src={IconFilterGray} alt="filter icon" />
            </button>
            {showPixelShieldPill && (
              <div className="image-container__pixel-shield-pill">
                <img src={DigitalPrintIconPink} alt="digital print icon" />
                <p className="pixel-shield-button__text">Pixel Shield</p>
              </div>
            )}
            {windowSize < 700 && (
              <img
                src={CloseIconWhite}
                alt="close icon"
                className="image-container__close-icon"
                onClick={closeModal}
              />
            )}

            {showMedia ? (
              <>
                {mediaToShow?.type === "photo" ? (
                  <img src={mediaToShow.media} alt="media captured" />
                ) : (
                  <div className="image-container__video-captured">
                    <video
                      onClick={playPauseVideo}
                      key={mediaToShow?.media}
                      ref={videoCapturedRef}
                      className="video-captured__video"
                    >
                      <source src={mediaToShow?.media} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                    {isPlayerIconShow && (
                      <img
                        src={PlayIconWhite}
                        alt="play icon"
                        onClick={playPauseVideo}
                      />
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <video className="image-container__image" ref={videoRef} />
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                {isHolding && (
                  <p className="video-recording__timer">{videoRecordingTime}</p>
                )}
                <div
                  className={`image-container__button-container ${
                    isHolding ? "video-recording" : ""
                  }`}
                >
                  <button
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    className={`button-container__recording-button ${
                      isHolding ? "video-recording" : ""
                    }`}
                    ref={recordingButtonRef}
                  ></button>
                </div>
                <figure
                  className="image-container__gallery-images"
                  onClick={openPersonalGalleryHandler}
                >
                  <img src={GridIconPink} alt="grid icon" />
                </figure>
                {capturedMedia.length > 0 && (
                  <figure
                    className="image-container__multi-images"
                    onClick={openCurrentGalleryHandler}
                  >
                    <img src={MultiImage} alt="multi icon" />
                  </figure>
                )}
              </>
            )}
          </section>
          <section
            className={`image-container__preview-media ${
              showMedia ? "position-absolute" : ""
            } ${
              (capturedMedia.length <= 6 && windowSize < 700) ||
              (capturedMedia.length <= 10 && windowSize >= 700)
                ? "justify-center"
                : ""
            } margin-vert-16`}
          >
            <div className="preview-media">
              {capturedMedia?.map((media, index) => (
                <div key={index} className="preview-media-container">
                  <img
                    src={media.type === "photo" ? media.media : media.thumbnail}
                    alt={`capture ${index}`}
                    className="preview-media__media"
                    onClick={() => setMediaToShow(media)}
                  />
                  {media === mediaToShow && (
                    <div
                      className="preview-media__delete-media"
                      onClick={() => removeMediaFromSharing(media)}
                    >
                      <img src={CloseIconPeach} alt="delete icon" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>
          {showMedia && (
            <section className="image-container__user-actions-container">
              <button
                className="user-actions-container__plus-button"
                onClick={startCamera}
              >
                <img src={PlusIconGray} alt="plus icon" />
              </button>
              <InputText
                inputType="text"
                placeholder="Description of content"
                value={descriptionContent}
                onChangeInput={setDescriptionContent}
              />
              {windowSize < 700 && (
                <button
                  className="user-actions-container__next-step-button"
                  onClick={shareContentSettings}
                >
                  <img src={ArrowRightPink} alt="arrow right icon" />
                </button>
              )}
            </section>
          )}
        </section>
      ) : (
        false
      )}

      {showConfig ? (
        <section className="share-content padding-24">
          <PageTitle
            title={"Share Content"}
            subtitle={"Configuration"}
            onBackClick={shareContentBackButtonHandler}
            hasCloseIcon={windowSize > 700}
            hasButtonNavigation={windowSize < 700}
            className="no-padding"
          />
          <div className="share-content__list-items">
            <ListItemToggle
              toggleCallBack={setIsExclusiveContent}
              hasSeparator={!isExclusiveContent}
              highlightText="Make Exclusive"
              simpleText="Monetize content"
              imageLeft={DollarIconGray}
              isImageCircle
              toggleInitialState={false}
            />
            {isExclusiveContent && (
              <>
                <InputText
                  inputType="text"
                  value={`Roses: ${rosesAmount}`}
                  iconLeft={RosesLogoIconPink}
                  onChangeInput={addRosesInputHandler}
                />
                <div className="share-content__separator margin-top-16" />
              </>
            )}
            {actionsList()}
          </div>
          <div className="add-media-page__buttons-container">
            {windowSize < 700 && (
              <Button buttonStyle="quaternary" onClick={cancelShareHandler}>
                Cancel
              </Button>
            )}
            <Button buttonStyle="primary" onClick={shareContentHandler}>
              Share
            </Button>
          </div>
        </section>
      ) : (
        false
      )}

      {showExclusiveList && (
        <section className="private-list">
          <PageTitle
            title={"Message"}
            subtitle={"Share privately to people"}
            onBackClick={privateListBackButton}
            hasCloseIcon={false}
          />

          <InputText
            searchInput
            inputType="text"
            placeholder="Search"
            onChangeInput={searchInConsumerList}
            value={inputSearchConsumer}
          />
          <section className="private-list__list">
            {filteredConsumerList.map((consumer, index, arr) => (
              <ListItemLink
                highlightText={consumer.name}
                hasSeparator={arr.length > index + 1}
                iconFill
                isImageCircle
                imageLeft={consumer.image}
                rightNodeContent={
                  <div className="private-list__button-send-container">
                    <button
                      onClick={() => addConsumerInListToSend(consumer.id)}
                      className={`button-send ${
                        chatIdsForSendContent.includes(consumer.id)
                          ? "sended-button"
                          : ""
                      }`}
                    >
                      {chatIdsForSendContent.includes(consumer.id)
                        ? "Sent"
                        : "Send"}
                    </button>
                  </div>
                }
              />
            ))}
          </section>

          <div className="add-media-page__buttons-container">
            {consumerList.length === chatIdsForSendContent.length &&
            chatIdsForSendContent.length !== 0 ? (
              <Button buttonStyle="tertiary" onClick={removeAllIdsToSend}>
                Remove all from list
              </Button>
            ) : (
              <Button buttonStyle="tertiary" onClick={shareWihAllHandler}>
                Share With All
              </Button>
            )}

            <Button buttonStyle="primary" onClick={finishSelectionHandler}>
              Done
            </Button>
          </div>
        </section>
      )}

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default AddMediaPage;
function onUploadComplete() {
  throw new Error("Function not implemented.");
}
