import { LandingPageTextSet } from "../types/types";

export const translateTextNodes = (translations: LandingPageTextSet) => {
  const textNodes = document.querySelectorAll("[textkey]");

  textNodes.forEach((node) => {
    const textKey = node.getAttribute("textkey");
    if (!textKey) return;

    const [page, key] = textKey.split("/");
    if (!page || !key || !page.match(/[0-9]/)) {
      console.error(
        `Invalid asset key: ${textKey} should be in format {page}/{text_key} and {page} must be a number`
      );
      return;
    }

    const pageId = parseInt(page);
    const pageContent = translations.pages.find(
      (p) => p.id === pageId
    )?.content;
    if (pageContent && key in pageContent) {
      const texto = pageContent[key];
      if (texto) {
        node.innerHTML = texto;
      }
    }
  });
};
