import React from "react";
import "./styles.scss";
import Verify from "../../../assets/icons/verify.svg";
import CameraIcon from "../../../assets/icons/feed-icons/camera-gray.svg";
import ImageIcon from "../../../assets/icons/feed-icons/image-gray.svg";
import CoinIcon from "../../../assets/icons/feed-icons/coin-gray.svg";
import EyeIcon from "../../../assets/icons/feed-icons/eye-gray.svg";
import iconList from "../../../mocks/allIcons";

interface FeedCardsProps {
  image: string;
  postTime: string;
  name: string;
  age: string;
  rating: number;
  isVerify: boolean;
  qnt_videos: number;
  qnt_photos: number;
  price_pack: string;
  views_post: string;
  isLockedContent: boolean;
  price: number | string;
  className?: string;
}

const FeedCards: React.FC<FeedCardsProps> = (props) => {
  const {
    age,
    name,
    postTime,
    rating,
    isVerify,
    image,
    price_pack,
    qnt_photos,
    qnt_videos,
    views_post,
    className,
  } = props;

  return (
    <div className={`feed-cards ${className ?? ""}`}>
      <div className="feed-creator-image-post">
        <img src={image} alt="creator feed post" />
      </div>

      <div className="feed-card-content">
        <p className="feed-card-time">{postTime}</p>
        <div className="principal-info">
          {/* <p className="feed-card-title">{name},</p>
          <p className="feed-card-title">{age}</p> */}
          {isVerify && <img src={Verify} alt="verify icon" />}
        </div>
      </div>

      <div className="creator-pack-media-info">
        <span className="creator-pack video">
          <span className="icon icon-video icon-md icon-gray" />
          <p className="no-margin qnt-media-text">{qnt_videos}</p>
        </span>
        <span className="creator-pack photo">
          <span className="icon icon-photo icon-md icon-gray" />
          <p className="no-margin qnt-media-text">{qnt_photos}</p>
        </span>
        <span className="creator-pack price">
          <span className="icon icon-money icon-md icon-gray" />
          <p className="no-margin qnt-media-text">{price_pack}</p>
        </span>
        <span className="creator-pack views">
          <img src={EyeIcon} alt="eye icon" />
          <p className="no-margin qnt-media-text">{views_post}</p>
        </span>
      </div>
    </div>
  );
};

export default FeedCards;
