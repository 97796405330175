import { useCallback, useState } from "react";
import { IToast } from "../types/toast";

const useToast = () => {
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
    timeout: 5000,
  });

  const handleToast = useCallback((config: IToast) => {
    setToastConfig(config);
    setToastShow(true);

    const toastTimeout = config.timeout || 5000;

    setTimeout(() => {
      setToastShow(false);
    }, toastTimeout);
  }, []);

  return { toastShow, toastConfig, setToastShow, handleToast };
};

export { useToast };
