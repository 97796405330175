import { useModal } from "../../../../hooks/useModal";
import React, { useRef } from "react";
import CloseIconGray from "../../../../assets/icons/closeIcons/close-icon-gray.svg";
import playIcon from "../../../../assets/icons/play-circle-white.svg";
import "../chat-modal-global-style.scss";

interface VideoMessageModalProps {
  url: string;
}

const VideoMessageModal: React.FC<VideoMessageModalProps> = (props) => {
  const { url } = props;

  const { setOpenUseModal } = useModal();
  const imagePlayerMessageRef = useRef<HTMLImageElement>(null);
  const videoMessageRef = useRef<HTMLVideoElement>(null);

  const playPauseHandler = () => {
    if (videoMessageRef?.current) {
      const isPaused = videoMessageRef.current.paused;

      if (imagePlayerMessageRef.current) {
        imagePlayerMessageRef.current.style.display = isPaused
          ? "none"
          : "block";
      }

      if (isPaused) {
        videoMessageRef.current.play();
      } else {
        videoMessageRef.current.pause();
      }
    }
  };

  return (
    <div className="modal-preview-file">
      <div className="header-modal-preview">
        <img
          src={CloseIconGray}
          alt="close icon"
          onClick={() => setOpenUseModal(false)}
          className="close-icon"
        />
      </div>

      <div className="preview-file-container">
        <img
          ref={imagePlayerMessageRef}
          src={playIcon}
          alt="play icon"
          className="play-icon"
          onClick={playPauseHandler}
        />
        <video
          className="file-modal-content"
          onClick={playPauseHandler}
          ref={videoMessageRef}
          autoPlay
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoMessageModal;
