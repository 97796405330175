import React, { useState, forwardRef } from "react";
import "./styles.scss";

import Loading from "../../Loading";
import AudioWaveform from "../../AudioWave";
import LockedFilesChat from "../../LockedFilesChat";
import ReadAudioChat from "../../ReadAudioChat";
import CardRosesSendedAndReceived from "../../Cards/CardRosesSendedAndReceived";

import ImageBlur from "../../../assets/images/image-blur.jpg";
import playIcon from "../../../assets/icons/play-circle-white.svg";

interface IChatMessage {
  id: string;
  side: string;
  message?: string;
  time?: string;
  translateDisclaimerContent?: string;
  fileSended?: IFile[];
  isMessageRead: boolean;
  messageSettings: {
    messageSettingsId: string;
    messageId: string;
    paidContent: boolean;
    price: number;
    isPaid: boolean;
  };
  chatRef?: React.LegacyRef<HTMLDivElement> | undefined;
  clickOnImage?: (a: any) => void;
  clickOnVideo?: (a: any) => void;
  clickOnPack?: (file: IFile[]) => void;
  unlockFileCallback: (a: string) => void;
}

interface IFile {
  url: string;
  fileType: string;
  mimetype: string;
  duration?: number;
  filePreview?: any;
}

const ChatMessage = forwardRef<HTMLDivElement, IChatMessage>(
  (
    {
      id,
      side,
      message,
      time,
      translateDisclaimerContent,
      fileSended,
      isMessageRead,
      messageSettings,
      chatRef,
      clickOnImage,
      clickOnVideo,
      clickOnPack,
      unlockFileCallback,
    },
    ref
  ) => {
    const unlockFile = () => unlockFileCallback(messageSettings.messageId);
    const isPaid = !!messageSettings?.isPaid;
    const paidContent = messageSettings?.paidContent;
    const files = fileSended?.length ? fileSended : [];

    const [isLoaded, setIsLoaded] = useState(false);

    const handleLoad = () => {
      setIsLoaded(true);
    };

    const classNameForFileWrapper = () => {
      if (!files.length) {
        return `text-message-wrapper ${side}-layout`;
      } else if (files[0]?.fileType === "AUDIO") {
        return `audio-file-wrapper ${side}-layout`;
      } else if (side === "left" && paidContent && !isPaid) {
        return `blocked-files-wrapper ${side}-layout`;
      } else if (files.length < 4) {
        return `single-file-wrapper ${side}-layout`;
      } else if (files.length >= 4) {
        return `file-grid-wrapper ${side}-layout`;
      } else if (!files.length) {
        return `text-message-container`;
      }
    };

    const renderLockedContent = (unlockCallback: () => void) => (
      <LockedFilesChat
        message="Unlock this content"
        captain={message}
        rosesPrice={messageSettings.price}
        unlockClick={unlockCallback}
        qntPhotos={
          files?.filter((file) => file?.fileType === "PHOTO")?.length || "0"
        }
        qntVideos={
          files?.filter((file) => file?.fileType === "VIDEO")?.length || "0"
        }
        lockedImageBlurred={
          fileSended ? fileSended[0]?.filePreview?.previewUrl : ImageBlur
        }
      />
    );

    const renderFileContent = (file: IFile, index: number) => {
      if (file?.fileType === "PHOTO") {
        return side === "left" && paidContent && !isPaid ? (
          renderLockedContent(unlockFile)
        ) : (
          <div className="photo-content-container">
            {!isLoaded && <Loading className="chat-loading" />}
            <img
              onLoad={handleLoad}
              key={`${messageSettings.messageSettingsId}${index}`}
              src={file.url}
              alt={file.url}
              className={`
              chat-file 
              file-${side} 
              ${isLoaded ? "chat-file-show" : ""}
              `}
              onClick={() => {
                if (clickOnImage) {
                  if ((paidContent && isPaid) || !paidContent) {
                    clickOnImage(file.url);
                  } else if (side === "right") {
                    clickOnImage(file.url);
                  }
                }
              }}
            />
          </div>
        );
      }

      if (file?.fileType === "VIDEO") {
        return side === "left" && paidContent && !isPaid ? (
          renderLockedContent(unlockFile)
        ) : (
          <div className={`video-content-container`}>
            {!isLoaded && <Loading className="chat-loading" />}
            <img src={playIcon} alt="play icon" className="play-icon" />
            <video
              key={`${messageSettings.messageSettingsId}${index}`}
              className={`
              chat-file 
              file-${side}
              ${isLoaded ? "chat-file-show" : ""}`}
              onLoadedData={handleLoad}
              onClick={() => {
                if (clickOnVideo) {
                  if ((paidContent && isPaid) || !paidContent) {
                    clickOnVideo(file.url);
                  } else if (side === "right") {
                    clickOnVideo(file.url);
                  }
                }
              }}
            >
              <source src={file.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }

      if (file?.fileType === "AUDIO") {
        return side === "left" && paidContent && !isPaid ? (
          renderLockedContent(unlockFile)
        ) : (
          <div className={`audio-content-container`}>
            <AudioWaveform audioSrc={file.url} duration={file.duration} />
          </div>
        );
      }
    };

    const renderGridFilesContent = () => {
      if (side === "right" || isPaid) {
        return (
          <>
            {files?.slice(0, 4).map((file, index) => (
              <div className={`file-chat-wrapper ${side}`} key={index}>
                {files.length > 4 && index + 1 === 4 ? (
                  <div className={`files-remains ${side}`}>
                    <p className="files-remains-text">+ {files.length - 3}</p>
                  </div>
                ) : (
                  <>
                    {file.fileType === "PHOTO" ? (
                      <div className="photo-content-container">
                        <img
                          key={`${messageSettings.messageSettingsId}${index}`}
                          src={file.url}
                          alt={file.url}
                          className={`chat-file file-${side}-${index + 1}`}
                        />
                      </div>
                    ) : (
                      file.fileType === "VIDEO" && (
                        <div className={`video-content-container 2`}>
                          <img
                            src={playIcon}
                            alt="play icon"
                            className="play-icon"
                          />
                          <video
                            key={`${messageSettings.messageSettingsId}${index}`}
                            className={`chat-file file-${side}-${index + 1}`}
                          >
                            <source src={file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        );
      } else {
        renderLockedContent(unlockFile);
      }
    };

    const renderGridFilesContentLeft = () => {
      if (side === "left" || isPaid) {
        return (
          <>
            {files?.slice(0, 4).map((file, index) => (
              <div className={`file-chat-wrapper ${side}`} key={index}>
                {files.length > 4 && index + 1 === 4 ? (
                  <div className={`files-remains ${side}`}>
                    <p className="files-remains-text">+ {files.length - 3}</p>
                  </div>
                ) : (
                  <>
                    {file.fileType === "PHOTO" ? (
                      <div className="photo-content-container">
                        <img
                          key={`${messageSettings.messageSettingsId}${index}`}
                          src={file.url}
                          alt={file.url}
                          className={`chat-file file-${side}-${index + 1}`}
                        />
                      </div>
                    ) : (
                      file.fileType === "VIDEO" && (
                        <div className={`video-content-container 2`}>
                          <img
                            src={playIcon}
                            alt="play icon"
                            className="play-icon"
                          />
                          <video
                            key={`${messageSettings.messageSettingsId}${index}`}
                            className={`chat-file file-${side}-${index + 1}`}
                          >
                            <source src={file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        );
      } else {
        renderLockedContent(unlockFile);
      }
    };

    const FileContainer = ({
      children,
      isLastItem,
    }: {
      children: React.ReactNode;
      isLastItem: boolean;
    }) => {
      return (
        <div
          id={id}
          className={`chat-message-component margin-bottom-8 messages-${side}`}
          ref={ref}
          onClick={() => {
            if (
              files.length >= 4 &&
              clickOnPack &&
              (isPaid || side === "right")
            ) {
              clickOnPack(files);
            }
          }}
        >
          {side === "left" && (
            <p className="message-info-container__time no-margin">{time}</p>
          )}

          <div className={`${classNameForFileWrapper()}`}>
            {children}

            {side === "left" &&
            paidContent &&
            !isPaid &&
            files[0]?.fileType !== "AUDIO" ? (
              <></>
            ) : (
              message &&
              isLastItem && (
                <div className="message-info-container">
                  <p className="no-margin message-info-container__message">
                    {message}
                  </p>
                </div>
              )
            )}
          </div>

          {side === "right" && (
            <p className="message-info-container__time no-margin">
              {time}
              <span
                className={`read-message-status ${
                  isMessageRead ? "message-read" : "unread-message"
                }`}
              />
            </p>
          )}

          {translateDisclaimerContent && (
            <span className={`disclaimer-container ${side}`}>
              {translateDisclaimerContent}
            </span>
          )}
        </div>
      );
    };

    return (
      <>
        {files && files?.length >= 2 && files?.length < 4 ? (
          <>
            {paidContent && !isPaid && side === "left" ? (
              <FileContainer isLastItem={true}>
                {renderLockedContent(unlockFile)}
              </FileContainer>
            ) : (
              files.map((file, index) => {
                const isLastItem = index === files.length - 1;

                return (
                  <FileContainer key={index} isLastItem={isLastItem}>
                    {!!files.length && (
                      <div className="file-chat-wrapper">
                        {renderFileContent(file, index)}
                      </div>
                    )}
                  </FileContainer>
                );
              })
            )}
          </>
        ) : (
          <>
            {paidContent && !isPaid && side === "left" ? (
              <>
                {/* {console.log("Exibindo conteúdo bloqueado", messageSettings, isPaid)} */}
                <FileContainer isLastItem={true}>
                  {renderLockedContent(unlockFile)}
                </FileContainer>
              </>
            ) : (
              <>
                {console.log(
                  "Exibindo conteúdo bloqueado",
                  messageSettings,
                  isPaid
                )}
                <FileContainer isLastItem={true}>
                  {files.length >= 4 && !isPaid && side === "left" ? (
                    renderGridFilesContentLeft()
                  ) : files.length >= 4 ? (
                    renderGridFilesContent()
                  ) : (
                    <>
                      {!!files?.length
                        ? files.map((file, index) => (
                            <div className="file-chat-wrapper" key={index}>
                              {renderFileContent(file, index)}
                            </div>
                          ))
                        : ""}
                    </>
                  )}
                </FileContainer>
              </>
            )}
          </>
        )}
      </>
    );
  }
);
export default ChatMessage;
