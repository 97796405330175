import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";

import { ICatalogResponse } from "../../../types/catalog";
import { IMomentsUnseen } from "../../../types/moments";

import { useDeskNavigation } from "../../../hooks/useDeskNavigation";

import { useIconContext } from "../../../contexts/iconNavContext";

import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../Buttons/ButtonNavigation";
import CardsCatalog from "../../Cards/CardsCatalog";
import MomentsSpheres from "../../Moments/MomentsSpheres";
import HeaderHomePage from "../../Headers/HeaderHomePage";
import HeaderMobileBottom from "../../Headers/HeaderMobileBottom";
import FeedPage from "../../../pages/Feed";

interface DeskProps {
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  catalogMain?: ReactNode;
  feedMain?: ReactNode;
  clickMoment: (a: string) => void;
  catalogHandlerCallback: (a: any) => void;
  clickProfileAvatar: (a: any) => void;
  profileAvatar?: string;
  hasANewMessage: boolean;
  onChangeInputSearch: (a: string) => void;
  valueInputSearch: string;
  favoritesClickHandler: (a: any) => void;
  momentsUnseen: IMomentsUnseen[] | [];
  momentsSeen: IMomentsUnseen[] | [];
  addMomentClick: (a: any) => void;
  clickChatList: (a: any) => void;
  catalogData: ICatalogResponse[];
}

const Desk: React.FC<DeskProps> = ({
  hasANewMessage,
  onChangeInputSearch,
  profileAvatar,
  valueInputSearch,
  principalMain,
  secondaryMain,
  tertiaryMain,
  catalogMain,
  clickMoment,
  catalogHandlerCallback,
  clickProfileAvatar,
  favoritesClickHandler,
  momentsUnseen,
  momentsSeen,
  addMomentClick,
  feedMain,
  clickChatList,
  catalogData,
}) => {
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen, params } =
    useDeskNavigation();
  const { fullscreen } = params;

  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const [isFeedMainVisible, setIsFeedMainVisible] = useState(false);
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [filterType, setFilterType] = useState<
    "all" | "vip" | "non-vip" | "guys" | "top-creators" | "girls"
  >("all");

  const [isPageFullScreen, setIsPageFullScreen] = useState(false);

  useEffect(() => {
    if (params && fullscreen) {
      setIsPageFullScreen(JSON.parse(fullscreen));
    }
  }, [fullscreen, params]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [isPrincipalMainVisible, isSecondaryMainVisible, isTertiaryMainVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
  }, [tertiaryMain]);

  useEffect(() => {
    if (feedMain) {
      setIsFeedMainVisible(true);
    } else {
      setIsFeedMainVisible(false);
    }
  }, [feedMain]);

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "All",
      clickAction: () => setFilterType("all"),
      id: 1,
      textRight: catalogData.length.toString(),
    },
    {
      text: "Girls",
      clickAction: () => setFilterType("girls"),
      id: 2,
      textRight: catalogData
        .filter(
          (catalog) =>
            catalog.profile.gender === "WOMAN" ||
            catalog.profile.gender === null
        )
        .length.toString(),
    },
    {
      text: "Guys",
      clickAction: () => setFilterType("guys"),
      id: 3,
      textRight: catalogData
        .filter((catalog) => catalog.profile.gender === "MAN")
        .length.toString(),
    },
    {
      text: "Top Creators",
      clickAction: () => setFilterType("top-creators"),
      id: 4,
      textRight: catalogData
        .filter((catalog) => catalog.isVip)
        .length.toString(),
    },
  ];

  return (
    <div id="desk-wrapper">
      {!isPageFullScreen && (
        <HeaderHomePage
          valueInputSearch={valueInputSearch}
          hasANewMessage={hasANewMessage}
          onChangeInputSearch={onChangeInputSearch}
          profileAvatar={profileAvatar}
          headerIcon="roses-logo"
          title="Roses™️"
          clickProfileAvatar={clickProfileAvatar}
          clickChatList={clickChatList}
          favoritesClick={favoritesClickHandler}
        />
      )}

      <main
        className={`main-page-desk ${
          isFeedMainVisible
            ? "grid-template-feed"
            : isPrincipalMainVisible || isSecondaryMainVisible
            ? `space-without-header ${
                isTertiaryMainVisible ? "grid-template-changed" : ""
              }`
            : ""
        }`}
      >
        <div
          className={`catalog-main ${
            isFeedMainVisible
              ? "hide-catalog-for-feed"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "hide-catalog-for-gallery"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "hide-catalog"
              : isSecondaryMainVisible && isPrincipalMainVisible
              ? "secondary-hide-catalog"
              : isSecondaryMainVisible && !isPrincipalMainVisible
              ? "only-secondary-main-is-show"
              : "show-catalog"
          }`}
        >
          {!isPageFullScreen && (
            <div
              className={`discover-nav-bar ${
                isFeedMainVisible ? "" : "box-shadow"
              }`}
            >
              <HeaderMobileBottom
                inputSearch={valueInputSearch}
                onChangeInput={onChangeInputSearch}
              />
              {!isFeedMainVisible && (
                <>
                  {momentsUnseen && (
                    <MomentsSpheres
                      clickMoment={clickMoment}
                      addMomentClick={addMomentClick}
                      momentsDetails={{ momentsSeen, momentsUnseen }}
                      className="add-moments-container__moments margin-left-24"
                    />
                  )}
                  <ButtonNavigation buttons={buttonsGroup} />
                </>
              )}
            </div>
          )}
          {!isFeedMainVisible && (
            <>
              {catalogMain || (
                <CardsCatalog
                  catalogHandlerCallback={catalogHandlerCallback}
                  className="catalog-component-main padding-hor-24"
                  filterType={filterType}
                  catalogData={catalogData}
                />
              )}
            </>
          )}
        </div>

        <div
          className={`feed-main ${
            isFeedMainVisible &&
            !isPrincipalMainVisible &&
            !isSecondaryMainVisible
              ? "feed-main-show"
              : isFeedMainVisible &&
                isPrincipalMainVisible &&
                !isSecondaryMainVisible
              ? "feed-principal-main-show"
              : isFeedMainVisible &&
                !isPrincipalMainVisible &&
                isSecondaryMainVisible
              ? "feed-secondary-main-show"
              : isFeedMainVisible &&
                isPrincipalMainVisible &&
                isSecondaryMainVisible
              ? "feed-principal-secondary-main-show"
              : ""
          }`}
        >
          {feedMain}
        </div>

        <div
          className={`principal-main ${
            isFeedMainVisible &&
            isPrincipalMainVisible &&
            !isSecondaryMainVisible
              ? "principal-feed-show"
              : isFeedMainVisible &&
                isPrincipalMainVisible &&
                isSecondaryMainVisible
              ? "principal-feed-secondary-show"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "show-principal-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                !isTertiaryMainVisible
              ? "show-principal-main show-principal-and-secondary-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-principal-main hide-principal-main-mobile"
              : "hide-principal-main"
          }`}
        >
          {principalMain}
        </div>

        <div
          className={`secondary-main ${
            isFeedMainVisible &&
            isPrincipalMainVisible &&
            isSecondaryMainVisible
              ? "secondary-feed-principal-show"
              : isFeedMainVisible &&
                !isPrincipalMainVisible &&
                isSecondaryMainVisible
              ? "secondary-feed-main-show"
              : isSecondaryMainVisible && !isTertiaryMainVisible
              ? "show-secondary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "show-secondary-main show-secondary-and-tertiary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                !isPrincipalMainVisible
              ? "show-secondary-main only-secondary-and-tertiary-main-show"
              : "hide-secondary-main"
          }`}
        >
          {secondaryMain}
        </div>

        <div
          className={`tertiary-main ${
            isFeedMainVisible
              ? "hide-tertiary-main-for-feed"
              : isTertiaryMainVisible &&
                isSecondaryMainVisible &&
                isPrincipalMainVisible
              ? "show-tertiary-main"
              : !isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-all-main"
              : "hide-tertiary-main"
          }`}
        >
          {tertiaryMain}
        </div>
      </main>
    </div>
  );
};

export default Desk;
