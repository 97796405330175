import "./styles.scss";

import allIcons from "../../mocks/allIcons";
import ButtonIcon from "../../components/Buttons/ButtonIcon";

const ShowAllButtonIcons: React.FC = () => {
  return (
    <div className="show-all-button-icons">
      {allIcons.map((icon, index) => (
        <div key={index}>
          <ButtonIcon icon={icon} />
          <p>{icon}</p>
        </div>
      ))}
    </div>
  );
};

export default ShowAllButtonIcons;
