import React, { useEffect, useState } from "react";
import "./styles.scss";

interface InputCheckboxProps {
  elements: InputCheckboxElement[];
  onChangeValueCallback: (a: string[]) => void;
  className: string;
  defaultValueProp: string[];
}
interface InputCheckboxElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const { elements, onChangeValueCallback, className, defaultValueProp } =
    props;
  const [inputValues, setInputsValues] = useState<string[]>(
    defaultValueProp || []
  );

  useEffect(() => {
    setInputsValues(defaultValueProp || []);
  }, [defaultValueProp]);

  const checkValueCallback = (
    event: React.ChangeEvent<HTMLInputElement>,
    element: string
  ) => {
    setInputsValues((prevValues) => {
      let updatedValues = [...prevValues];

      if (event.target.checked) {
        if (!updatedValues.includes(element)) {
          updatedValues.push(element);
        }
      } else {
        updatedValues = updatedValues.filter((value) => value !== element);
      }
      return updatedValues;
    });
  };

  return (
    <div id="input-checkbox-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container padding-hor-16" key={index}>
          <label
            htmlFor={`${element.inputLabel}-${index}`}
            className="container-checkbox padding-vert-16 no-margin"
          >
            {element.inputValue}
            <input
              id={`${element.inputLabel}-${index}`}
              name={element.groupName}
              value={element.inputValue}
              type="checkbox"
              onChange={(event) => {
                console.log(inputValues, "checked");
                checkValueCallback(event, element.inputValue);
              }}
              checked={inputValues.includes(element.inputValue)}
            />
            <span className="container-checkbox__checkmark"></span>
          </label>

          {index + 1 < elements.length && <div className="separator" />}
        </div>
      ))}
    </div>
  );
};

export default InputCheckbox;
