import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import FeedCards from "../../components/Cards/FeedCards";
import Image1 from "../../assets/images/feed-mock-images/feed-1.jpg";
import Image2 from "../../assets/images/feed-mock-images/feed-2.png";
import Image3 from "../../assets/images/feed-mock-images/feed-3.jpg";
import Image4 from "../../assets/images/feed-mock-images/feed-4.jpg";
import Image5 from "../../assets/images/feed-mock-images/feed-5.jpg";
import CoverPhoto from "../../assets/images/0542b1d078ce808d00317ebe68b3d139-full 2.png";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../components/Buttons/ButtonNavigation";
import CoverImage, { CreatorCoverInfo } from "../../components/CoverImage";
import PageTitle from "../../components/PageTitles";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import Button from "../../components/Buttons/Button";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";
import BalloonIconGray from "../../assets/icons/ballon-icon-gray.svg";
import iconList from "../../mocks/allIcons";
import { Params, useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import CreateChatContact from "../../api/postCreateChatContact";
import { useChat } from "../../contexts/openedChatContext";
import { Chat } from "../../types/chatInfo";
import { useWebSocket } from "../../contexts/webSocketContext";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "../../types/userInfo";
import postAddToFavorite from "../../api/favorite/postAddToFavorite";
import { IToast } from "../../types/toast";
import deleteRemoveFromFavorites from "../../api/favorite/deleteRemoveFromFavorites";
import ImageCircleBorder from "../../components/ImageCircleBorder";

const feedMock = [
  {
    image: Image1,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
  {
    image: Image2,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
  {
    image: Image3,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: true,
    price: 12,
  },
  {
    image: Image4,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: true,
    price: 32,
  },
  {
    image: Image5,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
];

type CreatorDetails = {
  creatorName: string;
  creatorAvatar: string;
  creatorAge: number | string;
  isVip: boolean;
};

const FeedPage: React.FC = () => {
  const {
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    params,
    setFeedScreen,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setMainScreen,
  } = useDeskNavigation();
  const { userId } = params;
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();
  const { setActiveChatId } = useChat();
  const divRef = useRef<HTMLDivElement | null>(null);

  const [postsTotal, setPostsTotal] = useState("100");
  const [photosTotal, setPhotosTotal] = useState("200");
  const [videosTotal, setVideosTotal] = useState("300");
  const [requestsTotal, setRequestsTotal] = useState("400");
  const [creatorInfo, setCreatorInfo] = useState<CreatorDetails>(
    {} as CreatorDetails
  );
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [toastShow, setToastShow] = useState(false);
  const [pageParams, setPageParams] = useState({} as Params);
  const [windowWidthSize, setWindowWidthSize] = useState(0);
  const [chats, setChats] = useState<Chat[]>([]);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getListChats = useCallback(async () => {
    getChats({}, (res: Chat[]) => {
      const openedChatsIds: string[] | [] = res?.map(
        (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
      );
      setOpenedChatUserIds(openedChatsIds);
      setChats(res);
    });
  }, [getChats, userId]);

  useEffect(() => {
    publicDetails();
  }, [componentFeed]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  useEffect(() => {
    setPageParams(params);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          setWindowWidthSize(Math.floor(width + 48));
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const publicDetails = async () => {
    try {
      const request = await getPublicDetails(userInfo.access_token, userId);
      const creator: IUserPublicInfo = request.res;

      setCreatorInfo({
        creatorAge: creator.profile.age,
        creatorAvatar:
          creator.profile.photos[0].thumbnailUrl ||
          creator.profile.photos[0].mediumUrl ||
          ProfileIcon,
        creatorName: creator.displayname,
        isVip: creator.isVip,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(userInfo.access_token, userId);
      switch (response.status) {
        case 201:
        case 204:
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        userId
      );

      switch (response.status) {
        case 204:
        case null:
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    }
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Posts",
      textRight: postsTotal,

      iconLeft: "star-outline",
      clickAction: () => {},
      id: 1,
    },
    {
      text: "Photos",
      textRight: photosTotal,
      iconLeft: "photo",
      clickAction: () => {},
      id: 2,
    },
    {
      text: "Videos",
      textRight: videosTotal,
      iconLeft: "video",
      clickAction: () => {},
      id: 3,
    },
    {
      text: "Requests",
      textRight: requestsTotal,
      iconLeft: "gifts",
      clickAction: () => {},
      id: 4,
    },
  ];

  const closeFeed = () => {
    setFeedScreen("");

    if (!componentPrincipal && componentSecondary) {
      setSecondaryScreen("");
    }
  };

  const subscribeHandler = () => {};

  const sendMessageClick = async () => {
    const creatorId = userId && userId !== "0" ? userId : pageParams.userId;

    if (creatorId) {
      const isOpenedChat = openedChatUserIds?.includes(creatorId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          // setActiveChatId(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  return (
    <div className="feed-page" ref={divRef}>
      <PageTitle
        onBackClick={closeFeed}
        backgroundTransparent
        className="feed-page__page-title padding-hor-24"
        hasCloseIcon={windowSize >= 768}
      />
      <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: creatorInfo.creatorName,
        }}
      />

      <main className={`feed-page-main padding-hor-16`}>
        <div
          className={`feed-page__interaction-nav ${
            windowWidthSize <= 560 ? "mobile-size" : ""
          }`}
        >
          <div className="interaction-nav__profile-info">
            <ImageCircleBorder
              centerImage={creatorInfo.creatorAvatar}
              centerImageAlt="user profile"
            />

            <div className="profile-info__name">
              <p>
                {creatorInfo.creatorName}
                {creatorInfo.isVip && (
                  <span className="icon icon-burn icon-md" />
                )}
              </p>
              <p>{creatorInfo.creatorAge} years</p>
            </div>
          </div>
          <div className="interaction-nav__interaction">
            <ButtonIcon
              onClick={clickLikeHandler}
              buttonStyle={
                isProfileFavorite ? "transparentActive" : "transparent-dawn"
              }
              icon={
                isProfileFavorite ? "icon-heart-solid" : "icon-heart-outline"
              }
            />
            <ButtonIcon
              onClick={sendMessageClick}
              icon={"icon-messages"}
              buttonStyle="transparent-dawn"
            />
            <Button buttonStyle="primary" onClick={subscribeHandler}>
              Subscribe {7.99}
            </Button>
          </div>
        </div>
        <ButtonNavigation
          buttons={buttonsGroup}
          className="margin-vert-24 padding-hor-24"
        />
        {feedMock.map((creator, index) => (
          <FeedCards
            key={index}
            image={creator.image}
            age={creator.age}
            isVerify={creator.isVerify}
            name={creator.name}
            postTime={creator.postTime}
            rating={creator.rating}
            qnt_videos={creator.qnt_videos}
            qnt_photos={creator.qnt_photos}
            price_pack={creator.price_pack}
            views_post={creator.views_post}
            isLockedContent={creator.isLockedContent}
            price={creator.price}
            className="margin-bottom-40"
          />
        ))}
      </main>
    </div>
  );
};

export default FeedPage;
