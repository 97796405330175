import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useWebSocket } from "../../contexts/webSocketContext";
import { useChat } from "../../contexts/openedChatContext";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";

import {
  Chat,
  IEventIsOnline,
  INewMessage,
  IReadMessage,
  Message,
} from "../../types/chatInfo";
import { ICatalogResponse } from "../../types/catalog";
import { IToast } from "../../types/toast";

import PageTitle from "../../components/PageTitles";
import ChatMessage from "../../components/Chat/ChatMessage";
import ChatTextarea from "../../components/TextAreas/ChatTextArea";
import SendAudioChat from "../../components/SendAudioChat";
import Loading from "../../components/Loading";
import ChatLocked from "../../components/Chat/ChatLocked";
import PackMessageModal from "../../components/Chat/ChatModals/PackMessageModal";
import VideoMessageModal from "../../components/Chat/ChatModals/VideoMessageModal";
import ImageMessageModal from "../../components/Chat/ChatModals/ImageMessageModal";
import PreviewPackModal from "../../components/Chat/ChatModals/PreviewPackModal";
import PreviewMediaModal from "../../components/Chat/ChatModals/PreviewMediaModal";
import UnlockContentModal from "../../components/Chat/ChatModals/UnlockContentModal";
import SendRosesModal from "../../components/Chat/ChatModals/SendRosesModal";
import CardExplanatory from "../../components/Chat/CardExplanatoryChat";
import Toast from "../../components/Toast";
import ChatButton from "../../components/Buttons/ChatButton";

import CreateChatContact from "../../api/postCreateChatContact";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import postUploadMedia from "../../api/chat/postUploadMedia";
import postUnlockChatContent from "../../api/chat/postUnlockContent";
import postSendRoses from "../../api/rosesTransactions/postSendRoses";
import getUserSelfDetail from "../../api/getUserSelfDetail";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { usePublishContent } from "../../hooks/usePublishContent";
import { useModal } from "../../hooks/useModal";
import { useScrollPosition } from "../../hooks/useScrollPosition";

import { hourFormat } from "../../utils/dateFormat";

import AddMediaPage from "../AddMediaPage";

import { keyWords } from "../../mocks/keyWords";
import ChatTyping from "../../components/Chat/ChatTyping";

const ChatPage: React.FC = () => {
  const {
    addChatListener,
    removeListener,
    sendMessage,
    getMessages,
    getChat,
    updateMessageStatus,
  } = useWebSocket();
  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    setFeedScreen,
    params,
  } = useDeskNavigation();
  const { creatorContent } = usePublishContent();
  const { setOpenUseModal, setModalContent } = useModal();

  const { userInfo } = useUserInfo();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);
  const inputChatRef = useRef<HTMLInputElement>(null);
  const [chatLocked, setChatLocked] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [chatInfo, setChatInfo] = useState<Chat>();
  const [hasAMomentToSee, setHasAMomentToSee] = useState(false);
  const [isContactOnline, setIsContactOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    isVip: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });
  const { activeChatId, setActiveChatId, setIsFirstMessage } = useChat();

  const [unreadMessageIds, setUnreadMessageIds] = useState<string[]>([]);
  const [userRosesAvailable, setUserRosesAvailable] = useState(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [readMessagesIds, setReadMessagesIds] = useState<string[]>([]);

  const messagesContainer = useRef<HTMLDivElement | null>(null);
  const chatIdRef = useRef(params.chatId);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const unreadMessagesTotal =
    Number(params.unreadCount) > 0 ? Number(params.unreadCount) : 0;

  // console.log(params);

  useEffect(() => {
    chatIdRef.current = params.chatId;
  }, [params.chatId]);

  useEffect(() => {
    const isSystemMessage = messages.at(-1)?.isSystemMessage ?? false;
    const hasMoreThatOneMessage = messages.length < 2;

    setIsFirstMessage(isSystemMessage && hasMoreThatOneMessage);
  }, [messages, setIsFirstMessage]);

  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const { isAtTop, isAtBottom, scrollPosition } =
    useScrollPosition(messagesContainer);

  useEffect(() => {
    if (Number(unreadMessagesTotal) === 0) {
      scrollToBottom();
    }
  }, []);

  useEffect(() => {
    handleMessages();
  }, [creatorContent]);

  const backPageHandler = () => {
    //TODO
    //need clear the params
    setSecondaryScreen("", {});
    setTertiaryScreen("");
    setActiveChatId(null);
    setIsChatPageOpen(false);
    removeAllListeners();
    setUnreadMessageIds([]);
  };

  const openMediaLibrary = () => {
    if (componentFeed) {
      setFeedScreen("/media-library", params);
    } else {
      setTertiaryScreen("/media-library", params);
    }
  };

  useEffect(() => {
    const getChatInfo = async () => {
      removeAllListeners();
      addChatListener(handleNewMessage);
      addChatListener(isContactOnlineListener);
      addChatListener(readMessageListener);
      handleMessages();
      getSelfDetail();
      // getChat(chatId, setChatInfo);
    };

    const isChatLocked = params.isLocked === "false" ? false : true;
    setChatLocked(!isChatLocked);
    if (isChatLocked && params.userId) {
      handleCreateChatContact();
    }

    producerDetail();
    getChatInfo();

    const scrollTimeout = setTimeout(scrollToBottom, 500);
    return () => {
      removeAllListeners();
      // clearTimeout(scrollTimeout);
    };
  }, [params.chatId, params.isLocked, params.userId]);

  const removeAllListeners = () => {
    removeListener("NEW_MESSAGE", handleNewMessage);
    removeListener("CONTACT_ONLINE", isContactOnlineListener);
    removeListener("READ_MESSAGE", readMessageListener);
  };

  useEffect(() => {
    if (unreadMessageIds.length > 0) {
      const messagesIds = new Set(unreadMessageIds);

      markMessagesAsRead(Array.from(messagesIds));
      // console.log("ChatId", activeChatId);
    }
  }, [unreadMessageIds, updateMessageStatus]);

  const scrollToElement = (messageId: string) => {
    if (messagesContainer.current) {
      const element = document.getElementById(messageId);
      if (element) {
        const containerTop = messagesContainer.current.offsetTop;
        const elementTop = element.offsetTop;
        messagesContainer.current.scrollTop = elementTop - containerTop;
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
    }
  };

  const getSelfDetail = async () => {
    const request = await getUserSelfDetail(userInfo.access_token);

    switch (request.status) {
      case 200:
        setUserRosesAvailable(request.res.quantityRoses);
        break;
      default:
        break;
    }
  };

  const handleMessages = () => {
    let chatId = params.chatId;
    const messageParams = {
      chatId,
      take: 15,
    };

    getMessages(messageParams, (messages: Message[]) => {
      const newMessages = messages.filter(
        (msg) => msg.chatId === params.chatId
      );
      setMessages([...newMessages]);
      // console.log(newMessages);
      const interestUnreadMessagesIds = messages
        .filter((msg) => !msg.isRead && msg.userId === params.userId)
        .map((msg) => msg.messageId);

      const lastMessageIsRight = userInfo.user_id === messages.at(-1)?.userId;

      if (interestUnreadMessagesIds.length > 0 && !lastMessageIsRight) {
        interestUnreadMessagesIds.forEach(addUnreadMessage);
        scrollToElement(interestUnreadMessagesIds[0]);
      } else {
        setTimeout(() => scrollToBottom(), 0);
      }
    });
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.currentTarget;
    const chatMessages = document.getElementById("chat-messages-container");

    if (scrollTop === 0 && messages.length > 0 && !loading) {
      const firstMessageId = messages[0].messageId;
      setLoading(true);
      const chatId = params.chatId;

      const messageParams = {
        chatId,
        take: 15,
        cursor: firstMessageId,
      };
      const previousHeight = chatMessages ? chatMessages.scrollHeight : 0;

      // Get new messages
      getMessages(messageParams, (newMessages: Message[]) => {
        const filteredMessages = newMessages.filter(
          (msg) => msg.chatId === chatId
        );
        setMessages((prevMessages) => [...filteredMessages, ...prevMessages]);
        setLoading(false);

        setTimeout(() => {
          if (chatMessages) {
            const newHeight = chatMessages.scrollHeight;
            chatMessages.scrollTop = newHeight - previousHeight;
          }
        }, 0);
      });
    }
  };

  const isContactOnlineListener = (event: IEventIsOnline) => {
    if (
      event.eventType === "USER_STATUS" &&
      event.payload.userId === params.userId
    ) {
      setIsContactOnline(event.payload.isOnline);
    }
  };

  const readMessageListener = (event: IReadMessage) => {
    if (params.userId) {
      if (event.eventType === "MESSAGE_READ") {
        setReadMessagesIds((prev) => [...prev, event.payload.messageId]);
      }
    }
  };

  const handleNewMessage = (event: INewMessage) => {
    if (event.eventType === "NEW_MESSAGE") {
      // console.log({
      //   "ChatIdRef: ": chatIdRef,
      //   "ActiveChatId: ": activeChatId,
      // });
      if (chatIdRef.current && event.payload.chatId === chatIdRef.current) {
        markMessagesAsRead([event.payload.messageId]);
      }

      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.messageId === event.payload.messageId
        );
        if (!isDuplicate) {
          return [...prevMessages, event.payload];
        }
        return prevMessages;
      });

      // console.log(event);

      // TODO: remove in future
      setIsTyping(false);

      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  };

  const markMessagesAsRead = (messageIds: string[]) => {
    updateMessageStatus(messageIds, (response) => {});
  };

  const addUnreadMessage = (newMessageId: string) => {
    setUnreadMessageIds((prevIds) => [...prevIds, newMessageId]);
  };

  // TODO: remove in future
  const containsKeyWord = (input: string) => {
    const lowerCaseInput = input.toLowerCase();
    const sanitizedInput = lowerCaseInput.replace(/[^a-z\s]/g, "");
    const words = sanitizedInput.split(/\s+/);
    for (const word of words) {
      if (keyWords.includes(word)) {
        return { found: true, word };
      }

      return { found: false, word: null };
    }
  };

  const [isTyping, setIsTyping] = useState(false);

  const handleSendMessage = async (text: string) => {
    if (chatLocked) {
      const canUnlock = await handleUnlockChatContact();

      if (!canUnlock) {
        return;
      }
    }

    // TODO: remove in future
    const isSpecialWord = containsKeyWord(text);

    try {
      // TODO: remove in future
      if (isSpecialWord && isSpecialWord.found) {
        setIsTyping(true);
        await new Promise<void>((resolve, reject) => {
          const chatId = params.chatId;
          sendMessage({ chatId, content: text }, (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            resolve();
            setTimeout(() => {
              scrollToBottom();
            }, 0);
          });
        });
      } else {
        await new Promise<void>((resolve, reject) => {
          const chatId = params.chatId;
          sendMessage({ chatId, content: text }, (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            resolve();
            setTimeout(() => {
              scrollToBottom();
            }, 0);
          });
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      inputChatRef.current?.focus();
    }
  };

  const handleUnlockChatContact = async () => {
    const canUnlock = await handlePurchasedChat();

    if (canUnlock) {
      await handleCreateChatContact();
      return true;
    }

    return false;
  };

  const handlePurchasedChat = async () => {
    if (userRosesAvailable >= 55) {
      setUserRosesAvailable((prev) => prev - 55);
      await postSendRoses(
        userInfo.access_token,
        producerDetails.userId,
        55,
        "Unlocked Chat"
      );

      handleToast({
        type: "success",
        title: "Purchased Done",
        description: "Congratulations",
      });

      return true;
    } else {
      handleToast({
        type: "info",
        title: "Unable to complete your purchase",
        description: "You don't have roses enough",
      });

      return false;
    }
  };

  const handleCreateChatContact = async () => {
    setChatLocked(false);

    const chatInfo = await CreateChatContact().postCreateChatContact(
      params.userId,
      userInfo.access_token
    );

    setIsContactOnline(chatInfo?.res?.chatParticipants[0]?.user?.isOnline);
  };

  const producerDetail = async () => {
    if (userInfo.access_token && params.userId) {
      const response = await getPublicDetails(
        userInfo.access_token,
        params.userId
      );

      switch (response?.status) {
        case 200:
          setProducerDetails(response.res);
          break;
        default:
          // setPrincipalScreen("");
          break;
      }
    }
  };

  const prepareSendFiles = (files: File[]) => {
    return files.map((file) => {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to read file"));
          }
        };
        reader.onerror = () => reject(new Error("File reading error"));

        reader.readAsDataURL(file);
      });
    });
  };

  const unlockContent = async (messageId: string) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );
    const canUnlock = await handlePurchasedChat();

    if (canUnlock) {
      await postUnlockChatContent(userInfo.access_token, messageId);
    }
    setOpenUseModal(false);
    setModalContent(null);
    handleMessages();
    // scrollToBottom();
  };

  const uploadFileCallBack = async (
    files: File[],
    content: string,
    thumbnail?: string
  ) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );
    const chatId = params.chatId;

    const prepareData = {
      chatId,
      content,
      price: 55,
      paidContent: true,
      files: files,
    };
    const response = await postUploadMedia(userInfo.access_token, prepareData);

    switch (response.status) {
      case 201:
        setTimeout(() => {
          scrollToBottom();
        }, 0);
        break;

      default:
        break;
    }

    setOpenUseModal(false);
    setModalContent(null);
    handleMessages();
  };

  const contentModalMedia = (files: File[]) => {
    const imagePromises = prepareSendFiles(files);
    const isVideoType = files[0].type.includes("video/") ? "video" : "image";

    Promise.all(imagePromises).then((images) => {
      setModalContent(
        <PreviewMediaModal
          files={files}
          images={images}
          fileType={isVideoType}
          uploadFileCallBack={uploadFileCallBack}
        />
      );
    });
    setOpenUseModal(true);
  };

  const contentModalPreviewPack = (files: File[]) => {
    let currentFiles = [...files];
    const removeFile = (indexToRemove: number) => {
      currentFiles = currentFiles.filter((_, index) => index !== indexToRemove);
      updateModalContent();
      if (!currentFiles.length) {
        setOpenUseModal(false);
      }
    };

    const updateModalContent = () => {
      const packPromises = prepareSendFiles(currentFiles);

      Promise.all(packPromises)
        .then((packSources) => {
          setModalContent(
            <PreviewPackModal
              files={files}
              packSources={packSources}
              removeFile={removeFile}
              uploadFileCallBack={uploadFileCallBack}
            />
          );
        })
        .catch((error) => {
          console.error("Error loading videos:", error);
        });
    };
    updateModalContent();
    setOpenUseModal(true);
  };

  const imageMessageModalHandler = (url: string) => {
    setModalContent(<ImageMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const videoMessageModalHandler = (url: string) => {
    setModalContent(<VideoMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const packMessageModalHandler = (
    urlsObj: {
      url: string;
      fileType: string;
      mimetype: string;
    }[]
  ) => {
    setModalContent(<PackMessageModal urlsObj={urlsObj} />);
    setOpenUseModal(true);
  };

  const sendRosesRequest = async (roses: number, message: string) => {
    await postSendRoses(userInfo.access_token, params.userId, roses, message);

    setOpenUseModal(false);
    handleMessages();
  };

  const sendRosesModalHandler = () => {
    setModalContent(
      <SendRosesModal
        title="Send Roses"
        description={
          <>
            Capture Your Love's Heart: <br />
            Send <b>Roses</b> Now!
          </>
        }
        showLogo
        showSendMessage
        maxValue={userRosesAvailable}
        onClose={() => setOpenUseModal(false)}
        rosesMessageCallback={sendRosesRequest}
        className="padding-16"
      />
    );
    setOpenUseModal(true);
  };

  const contentModalOpenCamera = () => {
    setModalContent(
      <AddMediaPage
        previousPage="chat"
        chatIdToSendContent={params.chatId}
        onUploadComplete={scrollToBottom}
      />
    );

    setOpenUseModal(true);
  };

  const clickMoment = () => {};

  const sendAudioToServer = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const audio = new File([audioBlob], `${userInfo.user_id}`, {
      type: audioBlob.type,
    });

    const chatId = params.chatId;

    const prepareData = {
      chatId,
      content: "",
      price: 55,
      paidContent: true,
      files: [audio],
    };

    await postUploadMedia(userInfo.access_token, prepareData);
    setTimeout(() => {
      scrollToBottom();
    }, 0);
    handleMessages();
  };

  const unlockContentSubmit = (messageId: string) => {
    setModalContent(
      <UnlockContentModal messageId={messageId} unlockContent={unlockContent} />
    );
    setOpenUseModal(true);
  };

  const handleToast = ({ type, title, description }: IToast) => {
    setToastShow(true);
    setToastConfig({
      type,
      title,
      description,
    });
    setTimeout(() => {
      setToastShow(false);
    }, 5000);
  };

  const [chatTextFocus, setChatTextFocus] = useState(false);
  const [chatTextHasContent, setChatTextHasContent] = useState(false);

  const handleMessageChange = (newMessage: string) => {
    setChatTextHasContent(newMessage ? true : false);
  };

  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  // const options = {
  //   root: messagesContainer.current,
  //   rootMargin: "0px",
  //   threshold: 1.0,
  // };

  // useEffect(() => {
  //   if (itemsRef.current.length === 0) return;

  //   const observer = new IntersectionObserver((entries, observer) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const beholded = entry.target;

  //         const messageId = beholded.id;
  //         const message = messages.find((msg) => msg.messageId === messageId);
  //         if (message && !message.isRead) {
  //           markMessagesAsRead([messageId]);
  //         }

  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   }, options);

  //   itemsRef.current.forEach((item) => {
  //     if (item) {
  //       observer.observe(item);
  //     }
  //   });

  //   return () => observer.disconnect();
  // }, [messages]);

  return (
    <div className="chat-container-page">
      <PageTitle
        isChat
        title={producerDetails?.displayname}
        //TODO
        // subtitle={isContactOnline ? "Online" : "Offline"}
        subtitle={"Online"}
        avatarUrl={producerDetails?.profile?.photos?.[0]?.url}
        onBackClick={backPageHandler}
        isOnLine={isContactOnline}
        mediaButtonClick={openMediaLibrary}
        chatLocked={chatLocked}
        clickMoment={clickMoment}
        hasAMomentToSee={hasAMomentToSee}
        hasCloseIcon={windowSize >= 768}
        className="padding-hor-24"
      />

      {/* {chatLocked ? (
        <ChatLocked onClickButton={handleUnlockChatContact} />
      ) : ( */}
      <>
        <div
          ref={messagesContainer}
          id="chat-messages-container"
          className="messages-container margin-hor-24"
          onScroll={handleScroll}
        >
          {
            // NOT DELETE it will use in future
            //messages.length <= 15 && (
            // <>
            //   <CardExplanatory
            //     title="Disappearing Messages"
            //     info={`You turned on disappearing messages. All new messages
            // 				 will disappear from this chat 24 hours after they’re
            // 				 sent.`}
            //     linkRoute=""
            //     linkText="Tap to change."
            //     className="margin-bottom-16"
            //   />
            //   <CardExplanatory
            //     title="Encrypted Messages"
            //     info={`Messages and calls are end-to-end encrypted. No one
            // 				outside of this chat, not even Roses, can read or listen
            // 				to them.`}
            //     linkRoute=""
            //     linkText="Tap to learn more."
            //     className="margin-bottom-16"
            //   />
            // </>
            //)
          }

          {messages
            .filter((msg) => msg.chatId === params.chatId)
            ?.map((message, index) => (
              <ChatMessage
                key={`${message.messageId}`}
                ref={(el) => {
                  if (el) {
                    itemsRef.current[index] = el;
                  }
                }}
                id={message.messageId}
                side={userInfo.user_id === message.userId ? "right" : "left"}
                message={message.content}
                time={hourFormat(message?.createdAt)}
                fileSended={message.files}
                messageSettings={message.messageSettings}
                clickOnImage={imageMessageModalHandler}
                clickOnVideo={videoMessageModalHandler}
                unlockFileCallback={unlockContentSubmit}
                clickOnPack={packMessageModalHandler}
                isMessageRead={
                  message.isRead || readMessagesIds.includes(message.messageId)
                }
              />
            ))}
          {isTyping && <ChatTyping />}
        </div>

        {isRecording ? (
          <SendAudioChat
            sendAudioCallback={sendAudioToServer}
            startRecordingCallback={setIsRecording}
            className="padding-hor-24"
          />
        ) : (
          <div className="actions-input-chat padding-hor-16">
            <ChatTextarea
              onSendMessage={handleSendMessage}
              isButtonSendDisabled={isLoading}
              onFocus={() => setChatTextFocus(true)}
              onBlur={() => setChatTextFocus(false)}
              onMessageChange={handleMessageChange}
              className="chat-page__message"
            >
              <ChatButton
                sendPack={contentModalPreviewPack}
                sendAudio={() => {
                  setIsRecording(true);
                }}
                sendMedia={contentModalMedia}
                sendRoses={sendRosesModalHandler}
                openCamera={contentModalOpenCamera}
                isButtonsDisabled={isLoading}
                parentFocus={chatTextFocus}
                parentHasContent={chatTextHasContent}
              />
            </ChatTextarea>
          </div>
        )}
      </>
      {/* )} */}
      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default ChatPage;
