import React from "react";
import "./styles.scss";

import Button from "../Buttons/Button";

import cameraIconGray from "../../assets/icons/camera-gray.svg";
import imagesIconGray from "../../assets/icons/images-icon-gray.svg";
import lockIconGray from "../../assets/icons/lock-icon-gray.svg";

interface LockedFilesChatProps {
  message: string;
  captain?: string;
  rosesPrice: string | number;
  qntPhotos: string | number;
  qntVideos: string | number;
  lockedImageBlurred: string;
  className?: string;
  unlockClick: (a: any) => void;
}

const LockedFilesChat: React.FC<LockedFilesChatProps> = (props) => {
  const {
    message,
    captain,
    rosesPrice,
    qntPhotos,
    qntVideos,
    lockedImageBlurred,
    className,
    unlockClick,
  } = props;
  return (
    <div
      className={`
        locked-files-chat 
        padding-4 
        ${className ? className : ""}`}
    >
      <div
        className={`locked-files-chat-locked ${
          !captain ? "locked-files-chat-locked-with-captain" : ""
        }`}
      >
        {lockedImageBlurred && (
          <img src={lockedImageBlurred} alt="locked images" />
        )}
        {/* <div className="content-info-container">
          <span className="text-icon">
            <img src={cameraIconGray} alt="camera icon" />
            <p className="no-margin">{qntVideos}</p>
          </span>
          <span className="text-icon">
            <img src={imagesIconGray} alt="images icon" />
            <p className="no-margin">{qntPhotos}</p>
          </span>
        </div> */}
        <div className="main-content">
          <img src={lockIconGray} alt="lock icon" />
          <h4 className="no-margin">{rosesPrice}</h4>
          {/* <p className="no-margin">{message}</p> */}
        </div>
        <Button
          className="button-locked-files"
          buttonStyle="quintenary"
          onClick={unlockClick}
        >
          Unlock
        </Button>
      </div>
      {captain && (
        <div className="chat-captain">
          <p className="chat-captain-text no-margin">{captain}</p>
        </div>
      )}
    </div>
  );
};

export default LockedFilesChat;
