import React from "react";
import "./styles.scss";

interface CoverImageProps {
  className?: string;
  creatorInfo: CreatorCoverInfo;
}

export interface CreatorCoverInfo {
  creatorCoverImage: string;
  creatorName: string;
}

const CoverImage: React.FC<CoverImageProps> = (props) => {
  const { className, creatorInfo } = props;

  return (
    <section id="cover-image" className={className ?? ""}>
      <div className={`cover-image__principal-image`}>
        <img
          src={creatorInfo.creatorCoverImage}
          alt={`${creatorInfo.creatorName} cover`}
          className="principal-image"
        />
      </div>
    </section>
  );
};

export default CoverImage;
