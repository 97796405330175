import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import mapMock from "../../assets/icons/image-mock/map-mock.png";
import { useEffect, useState } from "react";
import chevronRightIcon from "../../assets/icons/air-plane-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadio from "../../components/Inputs/InputRadio";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import { useUserInfo } from "../../hooks/userInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";

interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
}

const UserSettingsLocationPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const [residence, setResidence] = useState<string[]>([]);
  const [locationList, setLocationList] = useState<string[] | ListDetails[]>();
  const { userInfo } = useUserInfo();

  useEffect(() => {
    requestLocationHistory();
  }, [userInfo]);

  const requestLocationHistory = async () => {
    const response = await getUserLocationHistory(userInfo.access_token);

    if (response.res.currentLocation) {
      const currentLocation = [
        `${response.res.currentLocation.place}, ${response.res.currentLocation.country_code}`,
      ];
      setResidence(currentLocation);
    }

    if (response.res.locationHistory) {
      const history = response.res.locationHistory.map(
        (item: any) => item.location
      );
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
    }
  };

  const updateLocation = (array: any) => {
    let data;
    /* update */

    locationList?.map(async (item: any) => {
      const place = [`${item.place}, ${item.country_code}`];
      if (place[0] === residence[0]) {
        data = item;
        const response = await patchUserLocation(userInfo.access_token, data);
      }
    });
  };

  return (
    <div className="user-settings">
      <PageTitle
        title={"Change Location"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings-profile")}
      />

      <div className="div__img"></div>

      <div className="div__content_scroll margin-hor-24">
        {/*  <img src={mapMock} alt="" className="img_mock" /> */}

        <InputText
          iconLeft={chevronRightIcon}
          value={residence}
          onChangeInput={() => {}}
          inputType="text"
          placeholder="Select Location"
          readOnly={true}
        />

        <div className="separator-margin" />

        <InputRadio
          elements={
            locationList
              ? locationList.map((item: any) => {
                  return {
                    groupName: "country",
                    inputLabel: `${item.place}, ${item.country_code}`,
                    inputValue: `${item.place}, ${item.country_code}`,
                  };
                })
              : []
          }
          onChangeValueCallback={(e) => {
            setResidence(e);
          }}
          defaultValueProp={residence}
        />

        <div className="separator-margin" />

        <Button
          children={"Upgrade to change location"}
          buttonStyle={"primary"}
          onClick={() => updateLocation(residence)}
        />
      </div>
    </div>
  );
};

export default UserSettingsLocationPage;
