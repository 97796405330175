import React, { ChangeEvent, useEffect, useState } from "react";
import "./styles.scss";
import Camera from "../../assets/icons/camera-icon-gray.svg";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";

interface PhotosPlaceholderProps {
  quantityFiles: number;
  photoAlbumCallback: (files: (File | null)[]) => void;
  removeFileCallback?: (fileId: string) => void;
  images?: { fileId: string; url: string }[];
  video?: string;
  removeVideo?: () => void;
}

const PhotosPlaceholder: React.FC<PhotosPlaceholderProps> = (props) => {
  const {
    quantityFiles,
    photoAlbumCallback,
    removeFileCallback,
    images,
    video,
    removeVideo,
  } = props;
  const [files, setFiles] = useState<(File | null)[]>(
    Array(quantityFiles).fill(null)
  );

  const [mediaArray, setMediaArray] = useState<string[]>([]);

  useEffect(() => {
    if (images) {
      const newFiles = Array(quantityFiles).fill(null);
      images.forEach((image, index) => {
        if (index < quantityFiles) {
          newFiles[index] = null;
        }
      });
      setFiles(newFiles);
    }
  }, [images, quantityFiles]);

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFiles = [...files];
    if (e.target.files && e.target.files[0]) {
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
      photoAlbumCallback(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    if (images && images[index]) {
      removeFileCallback?.(images[index].fileId);
    }
    newFiles[index] = null;
    setFiles(newFiles);
    photoAlbumCallback(newFiles);
  };

  return (
    <div id="photos-placeholder">
      {Array.from({ length: quantityFiles }).map((_, index) => {
        const indexWithVideo = video ? index - 1 : index;
        return (
          <>
            <div
              className={`photo-preview preview-images ${
                index === 0 ? "large-image" : ""
              }`}
              key={index}
            >
              {index === 0 && video ? (
                <div className="preview-images">
                  <video
                    src={video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    disablePictureInPicture
                    controls={false}
                    preload="auto"
                  />
                  <button onClick={removeVideo}>
                    <img src={CloseIconPeach} alt="remove icon" />
                  </button>
                </div>
              ) : null}

              {images && images[indexWithVideo] ? (
                <div className="preview-images">
                  <img
                    src={images[indexWithVideo]?.url}
                    alt={`preview ${indexWithVideo}`}
                  />
                  <button onClick={() => handleRemoveFile(indexWithVideo)}>
                    <img src={CloseIconPeach} alt="remove icon" />
                  </button>
                </div>
              ) : (
                <>
                  {!files[indexWithVideo] ? (
                    <span className="empty-photo">
                      <img src={Camera} alt="camera icon" />
                    </span>
                  ) : (
                    ""
                  )}

                  <input
                    type="file"
                    name="upload-photo"
                    onChange={(e) => handleFileChange(e, index)}
                    // accept={
                    //   index === 0
                    //     ? ".jpg,.jpeg,.png,.gif,.mp4"
                    //     : ".jpg,.jpeg,.png"
                    // }
                  />

                  {files[index] && (
                    <div className="preview-images">
                      {files[index]?.name.toLowerCase().endsWith(".mp4") ? (
                        <video
                          src={URL.createObjectURL(files[index] as File)}
                          autoPlay
                          loop
                          muted
                          playsInline
                          disablePictureInPicture
                          controls={false}
                          preload="auto"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(files[index] as File)}
                          alt={`preview ${index}`}
                        />
                      )}
                      <button
                        onClick={() => {
                          if (
                            !files[index]?.name.toLowerCase().endsWith(".mp4")
                          ) {
                            handleRemoveFile(index);
                          } else {
                            removeVideo?.();
                          }
                        }}
                      >
                        <img src={CloseIconPeach} alt="remove icon" />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default PhotosPlaceholder;
