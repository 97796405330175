import React from "react";
import "./styles.scss";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";

interface ImageCircleBorderProps {
  className?: string;
  centerImage?: string;
  centerImageAlt: string;
  onClick?: (a: any) => void;
  hasPinkBorder?: boolean;
  size?: 40 | 48 | 56;
}

const ImageCircleBorder: React.FC<ImageCircleBorderProps> = (props) => {
  const {
    className,
    centerImage,
    centerImageAlt,
    onClick,
    hasPinkBorder,
    size = 48,
  } = props;

  const imageCircleBorderClasses = [onClick ? "clickable" : "", className ?? ""]
    .join(" ")
    .trim();

  return (
    <figure
      id="image-circle-border"
      className={imageCircleBorderClasses}
      onClick={onClick}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <img
        src={centerImage ?? ProfileIcon}
        alt={centerImageAlt}
        loading="lazy"
        style={{ width: `${size - 2}px`, height: `${size - 2}px` }}
        className={`image-circle-border__center-image ${
          hasPinkBorder ? "pink-border" : "charcoal-border"
        }`}
      />
    </figure>
  );
};

export default ImageCircleBorder;
